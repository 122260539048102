import {
	AppLanguage,
	AppLanguageCode,
	CountryCode,
} from "@cruncho/cruncho-shared-types";
import { Locale } from "date-fns";
import {
	da,
	de,
	enGB,
	es,
	fr,
	it as itLocale, // `it` is a reserved word in TypeScript apparently
	nb,
	nl,
	pt,
	ro,
	ru,
	sv,
	zhCN,
	zhTW,
} from "date-fns/locale";

import { CC_TO_APPLANGUAGECODE_MAP, CC_TO_LOCALE_MAP } from "../consts";

export function shortenLanguageCode(appLanguage: AppLanguage): AppLanguageCode {
	switch (appLanguage) {
		case "zh-Hans-CN":
			return "zh_Hans";
		case "zh-Hant-TW":
			return "zh_Hant";
		default:
			return appLanguage.split("-")[0] as AppLanguageCode;
	}
}

export const APP_DEFAULT_LOCALE: AppLanguage = "en-GB"; // Used only for default value of the state before we initialise the real value. The real fallback default value is the first available cc from the city features

/**
 * Convert a locale code to a country code
 *
 *
 */
export function localeTocc(
	appLanguage: AppLanguage,
	availableCCs: Readonly<CountryCode[]>,
): CountryCode {
	const entry = Object.entries(CC_TO_LOCALE_MAP).find(
		([code, locale]) =>
			locale === appLanguage && availableCCs.includes(code as CountryCode),
	) as [CountryCode, AppLanguage] | undefined;

	return entry ? (entry[0] as CountryCode) : availableCCs[0];
}

/**
 * Convert a country code to the locale we use internally in the apps
 *
 * e.g. DK => da-DK
 */
export function ccToLocale(cc: CountryCode): AppLanguage {
	return CC_TO_LOCALE_MAP[cc];
}

/**
 * Convert a country code to the country code we use internally in the apps
 *
 * e.g: DK => da
 */
export function ccToAppLanguageCode(
	cc: CountryCode | "en-US",
	available_ccs: Readonly<CountryCode[]> = [],
	useUSFlag?: boolean,
): AppLanguageCode {
	if (!!cc.match(/en.*/)?.length && !!useUSFlag) {
		return "en-US";
	}

	return (
		CC_TO_APPLANGUAGECODE_MAP[cc as CountryCode] ||
		CC_TO_APPLANGUAGECODE_MAP[available_ccs[0]] ||
		APP_DEFAULT_LOCALE
	);
}

/**
 * Get the `date-fns` locale corresponding to a {@link AppLanguage}
 */
export function convertAppLanguageToDateFnsLocale(
	language: AppLanguage,
): Locale {
	switch (language) {
		case "en-GB":
			return enGB;
		case "sv-SE":
			return sv;
		case "fr-FR":
			return fr;
		case "ro-RO":
			return ro;
		case "de-DE":
			return de;
		case "ca-ES":
			return es;
		case "es-ES":
			return es;
		case "ru-RU":
			return ru;
		case "nl-NL":
			return nl;
		case "it-IT":
			return itLocale;
		case "zh-Hans-CN":
			return zhCN;
		case "zh-Hant-TW":
			return zhTW;
		case "pt-PT":
			return pt;
		case "da-DK":
			return da;
		case "nb-NO":
			return nb;
		default:
			return enGB;
	}
}
