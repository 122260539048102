import { StructuredReco } from "@cruncho/cruncho-shared-types";
import {
	propertyResetToOriginal,
	propertyResetToSaved,
	propertyUpdate,
} from "./actions";
import { AppState } from "./configureStore";

export function createPropsForProperty(
	state: AppState,
	property: keyof StructuredReco,
): DefaultPropertyProps {
	return {
		hasSavedChanges:
			state.selectedReco.diffOriginalSaved.hasOwnProperty(property),
		hasUnsavedChanges:
			state.selectedReco.diffSavedUnsaved.hasOwnProperty(property),
		value: state.selectedReco.unsavedModifiedReco
			? state.selectedReco.unsavedModifiedReco[property]
			: false,
		property,
	};
}

export function createDispatchForProperty(
	dispatch: any,
	property: keyof StructuredReco,
) {
	return {
		resetToOriginal: () => dispatch(propertyResetToOriginal(property)),
		resetToSaved: () => dispatch(propertyResetToSaved(property)),
		updateValue: (value: any) => dispatch(propertyUpdate(property, value)),
	};
}

export function createDefaultDispatch(
	dispatch: any,
	properties: Array<keyof StructuredReco>,
) {
	const defaultDispatch: any = {};
	properties.forEach((property) => {
		defaultDispatch[property] = createDispatchForProperty(dispatch, property);
	});
	return defaultDispatch;
}

export function createDefaultProps<T extends StructuredReco>(
	state: AppState,
	properties: Array<keyof T>,
): {
	[key in keyof T]: DefaultPropertyProps;
} {
	const defaultProps: any = {};
	properties.forEach((property) => {
		defaultProps[property] = createPropsForProperty(
			state,
			property as keyof StructuredReco,
		);
	});
	return defaultProps;
}

export interface DefaultPropertyProps {
	hasSavedChanges: boolean;
	hasUnsavedChanges: boolean;
	value: any;
	property: string;
}

export interface DefaultPropertyDispatch {
	resetToOriginal: () => void;
	resetToSaved: () => void;
	updateValue: (val: any) => void;
}
