import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface PinnedSettingState {
	isPinned: boolean;
}

const pinnedSettingSlice = createSlice({
	name: "pinnedSetting",
	initialState: { isPinned: false } as PinnedSettingState,
	reducers: {
		updatePinned(state, action: PayloadAction<boolean | undefined>) {
			state.isPinned = action.payload || false;
		},
	},
});

export const { updatePinned } = pinnedSettingSlice.actions;

export default pinnedSettingSlice.reducer;
