import { z } from "zod";
import { L1ToType } from "./destination";
import { l1Schema } from "./recommendation";

const slugSchema = z
	.string()
	.describe("Unique string that is mapped to the APIs categories")
	.refine((s) => !s.includes(" "), "Slug shouldn't contain spaces");

export const categorySchema = z
	.object({
		count: z.number().optional(),
		default: z.boolean().describe("Is part of the default tree"),
		hideOnEM: z.boolean().describe("Hide on EventManager but not guide"),
		order: z.number().optional(),
		slug: slugSchema,
	})
	.describe("A base schema for a category. Corresponds also to L1 categories");
export type Category = z.infer<typeof categorySchema>;

export const childCategorySchema = categorySchema
	.merge(
		z.object({
			parentSlug: slugSchema,
		}),
	)
	.describe(
		"Schema of a category having one parent. Corresponds to L3 categories",
	);
export type ChildCategory = z.infer<typeof childCategorySchema>;

export const l2CategorySchema = childCategorySchema.merge(
	z.object({
		parentSlug: l1Schema,
	}),
);
export type L2Category = z.infer<typeof l2CategorySchema>;

const childAndParentCategorySchema = l2CategorySchema
	.merge(
		z.object({
			children: childCategorySchema.array(),
		}),
	)
	.describe("An l2 category schema with a tree structure, having l3 children");
export type ChildAndParentCategory = z.infer<
	typeof childAndParentCategorySchema
>;

export const l1CategorySchema = categorySchema.merge(
	z.object({
		slug: l1Schema,
	}),
);
export type L1Category = z.infer<typeof l1CategorySchema>;

const categoryTreeSchema = l1CategorySchema
	.merge(
		z.object({
			children: childAndParentCategorySchema.array(),
		}),
	)
	.describe("An l1 category schema with a tree structure, having l2 children");
export type CategoryTree = z.infer<typeof categoryTreeSchema>;

export const mergedCategoriesSchema = L1ToType(
	z
		.object({
			l2: z.string().array(),
			l3: z.string().array(),
		})
		.describe(
			"A schema to gather all l2 and l3 categories of a l1 into one array",
		),
);
export type MergedCategories = z.infer<typeof mergedCategoriesSchema>;

const eventsAPIExtensions = z.object({
	numberOfEvents: z.number(),
	displayName: z.string().optional(),
});
const eventsApiOmitFields = {
	default: true,
	hideOnEM: true,
} as { default: true; hideOnEM: true };

export const eventsAPIChildCategorySchema = childCategorySchema
	.omit(eventsApiOmitFields)
	.merge(eventsAPIExtensions);
export type EventsAPIChildCategory = z.infer<
	typeof eventsAPIChildCategorySchema
>;

export const eventsApiChildAndParentCategorySchema =
	eventsAPIChildCategorySchema.merge(
		z.object({
			children: eventsAPIChildCategorySchema.partial().array(),
		}),
	);
export type EventsAPIChildAndParentCategory = z.infer<
	typeof eventsApiChildAndParentCategorySchema
>;

export const eventsApiCategoryTreeSchema = categorySchema
	.omit(eventsApiOmitFields)
	.merge(eventsAPIExtensions)
	.merge(
		z.object({
			children: eventsApiChildAndParentCategorySchema.partial().array(),
		}),
	);
export type EventsAPICategoryTree = z.infer<typeof eventsApiCategoryTreeSchema>;
