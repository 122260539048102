import { z } from "zod";

export const ERROR_CODES = [
	"OUTSIDE_POLYGON",
	"FAILED_CREATE_BOOKING_SESSION",
] as const;
export const ERROR_SYMBOLS = ["10001", "10002"] as const;

/**
 * Standardized Error code
 */
export const errorCodeSchema = z.enum(ERROR_CODES);
export const errorSymbolSchema = z.enum(ERROR_SYMBOLS);

export type ErrorCode = z.infer<typeof errorCodeSchema>;
export type ErrorSymbolSchema = z.infer<typeof errorSymbolSchema>;

export const ERROR_CODES_MAP: Record<ErrorCode, ErrorSymbolSchema> = {
	// Coordinates are situated outside the polygon
	OUTSIDE_POLYGON: "10001",
	// SoulCircus: failed to create the session
	FAILED_CREATE_BOOKING_SESSION: "10002",
};
