import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const categoriesSlice = createSlice({
	name: "categories",
	initialState: [] as string[],
	reducers: {
		setCategories(state, action: PayloadAction<string[]>) {
			return action.payload;
		},
		addCategory(state, action: PayloadAction<string>) {
			return [...state, action.payload];
		},
		removeCategory(state, action: PayloadAction<string>) {
			return state.filter((category) => category !== action.payload);
		},
	},
});

export const { setCategories, addCategory, removeCategory } =
	categoriesSlice.actions;

export default categoriesSlice.reducer;
