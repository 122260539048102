import {
	AvailableApisEnum,
	EnvApiConfig,
	EnvConfig,
	L1,
} from "@cruncho/cruncho-shared-types";

export const imageRandomNumber = (recoId: string, nbImages: number) =>
	(recoId.split("").reduce((sum, letter) => sum + letter.charCodeAt(0), 0) %
		nbImages) +
	1;

/**
 * Return a default image URL for a recommendation.
 *
 * @param tag
 * @param l1Tag
 * @param recoId
 * @returns
 */
export const getTagImageURL = (tag: string, l1Tag: L1, recoId: string) => {
	let imageName: string;
	let imageNumber: number;
	switch (tag) {
		case "amusement parks" ||
			"bowling" ||
			"classes & workshops" ||
			"games & arcades" ||
			"hiking" ||
			"Kids & Family" ||
			"kids & family" ||
			"luxury & special occasions" ||
			"outdoor activities" ||
			"shows, concerts & sports" ||
			"stadiums & arenas" ||
			"theme parks" ||
			"water activities":
			imageNumber = imageRandomNumber(recoId, 18);
			imageName = `activities/${imageNumber}`;
			break;

		case "arts & culture" ||
			"arts and culture" ||
			"performing & visual arts" ||
			"art & culture" ||
			"art galleries" ||
			"cinemas" ||
			"festivals" ||
			"jazz & blues clubs" ||
			"museums" ||
			"music venues" ||
			"opera & concert halls" ||
			"street art" ||
			"theatres" ||
			"various events & classes":
			imageNumber = imageRandomNumber(recoId, 13);
			imageName = `art/${imageNumber}`;
			break;

		case "air sports" ||
			"ball sports" ||
			"bike & mountain bike" ||
			"equestrian" ||
			"golf" ||
			"gym, dance & fitness" ||
			"martial arts" ||
			"motor sports" ||
			"motorsports" ||
			"other sports & activities" ||
			"skating" ||
			"ski & mountain activities" ||
			"swimming pools":
			imageNumber = imageRandomNumber(recoId, 20);
			imageName = `training-sports/${imageNumber}`;
			break;

		case "cars & motorcycles" || "auto, boat & air":
			imageName = "cars-motorcycles";
			break;

		case "books & newspapers" ||
			"concept stores" ||
			"fashion & clothes" ||
			"gifts & souvenirs" ||
			"kids & toys shop" ||
			"markets & bazaars" ||
			"music, video & games" ||
			"second hand" ||
			"shopping tours" ||
			"sport & outdoor":
			imageNumber = imageRandomNumber(recoId, 9);
			imageName = `shopping/${imageNumber}`;
			break;

		case "bathing site" ||
			"monuments & landmarks" ||
			"natural surroundings" ||
			"parks & gardens" ||
			"religious buildings":
			imageNumber = imageRandomNumber(recoId, 11);
			imageName = `landmarks-nature/${imageNumber}`;
			break;

		case "cruises & flying tours" ||
			"cultural & theme tours" ||
			"day trips" ||
			"multi-day trips" ||
			"holiday & seasonal tours" ||
			"private & exclusive tours" ||
			"shore excursions" ||
			"sightseeing tours" ||
			"walking & biking tours":
			imageNumber = imageRandomNumber(recoId, 2);
			imageName = `guided-tours/${imageNumber}`;
			break;

		case "campsites" || "hostel" || "hotel" || "resorts" || "vacation rentals":
			imageNumber = imageRandomNumber(recoId, 5);
			imageName = `accommodations/${imageNumber}`;
			break;

		case "food & wine tours":
			imageNumber = imageRandomNumber(recoId, 2);
			imageName = `food-tours/${imageNumber}`;
			break;

		case "massage" || "piercing & tattoos" || "spa tours":
			imageNumber = imageRandomNumber(recoId, 3);
			imageName = `wellness-beauty/${imageNumber}`;
			break;

		case "religion & beliefs" ||
			"religion & spirituality" ||
			"new age & spirituality" ||
			"religion-spirituality":
			imageNumber = imageRandomNumber(recoId, 3);
			imageName = `religion-spirituality-beliefs/${imageNumber}`;
			break;

		case "bars" ||
			"casinos" ||
			"cocktail bars" ||
			"karaoke" ||
			"lgbt-friendly" ||
			"nightclubs" ||
			"nightlife" ||
			"pub & sports bars" ||
			"snooker & pool halls":
			imageNumber = imageRandomNumber(recoId, 11);
			imageName = `bars-nightlife/${imageNumber}`;
			break;

		case "african" ||
			"american" ||
			"asian" ||
			"caribbean" ||
			"chinese" ||
			"european" ||
			"french" ||
			"greek" ||
			"indian" ||
			"international" ||
			"italian" ||
			"japanese" ||
			"korean" ||
			"latin american" ||
			"local flavor" ||
			"mexican" ||
			"middle eastern" ||
			"portuguese" ||
			"scandinavian" ||
			"spanish" ||
			"thai" ||
			"turkish" ||
			"vietnamese":
			imageNumber = imageRandomNumber(recoId, 21);
			imageName = `cuisine-styles/${imageNumber}`;
			break;

		case "tapas" ||
			"fast food" ||
			"food courts" ||
			"food trucks" ||
			"salads" ||
			"sandwiches" ||
			"soups" ||
			"street food":
			imageNumber = imageRandomNumber(recoId, 7);
			imageName = `fast-food-bites/${imageNumber}`;
			break;

		case "gluten free" || "juice bars" || "raw" || "vegan" || "vegetarian":
			imageNumber = imageRandomNumber(recoId, 4);
			imageName = `vegetarian-vegan-raw/${imageNumber}`;
			break;

		case "bakeries" || "cafes" || "ice cream" || "tea":
			imageNumber = imageRandomNumber(recoId, 4);
			imageName = `cafes-bakeries/${imageNumber}`;
			break;

		case "airport transfers" || "bike & motorbike rentals":
			imageNumber = imageRandomNumber(recoId, 2);
			imageName = `transports/${imageNumber}`;
			break;

		case "career & business" || "business & professional":
			imageName = "career-business";
			break;

		case "community & environment" || "community & culture":
			imageName = "community-environment";
			break;

		case "hobbies & crafts" || "hobbies & special interest":
			imageName = "hobbies-crafts";
			break;

		case "dancing":
			imageNumber = imageRandomNumber(recoId, 4);
			imageName = `dancing/${imageNumber}`;
			break;

		case "education & learning" || "family & education":
			imageName = "education-learning";
			break;

		case "movies & film" || "film, media & entertainment":
			imageName = "movies-film";
			break;

		case "fitness":
			imageName = "fitness";
			break;

		case "games":
			imageName = "games";
			break;

		case "language & ethnic identity":
			imageName = "language-ethnic-identity";
			break;

		case "LGBT":
			imageName = "LGBT";
			break;

		case "literature & writing":
			imageName = "literature-writing";
			break;

		case "parents & family" || "kids & family ": // "kids & family" found in the "Do" category
			imageName = "parents-family";
			break;

		case "movements & politics" || "government & politics":
			imageName = "movements-politics";
			break;

		case "music" || "music events":
			imageName = "music";
			break;

		case "outdoors & adventure" || "travel & outdoor":
			imageName = "outdoors-adventure";
			break;

		case "paranormal":
			imageName = "paranormal";
			break;

		case "pets & animals":
			imageName = "pets-animals";
			break;

		case "sci-fi & fantasy":
			imageName = "scifi-fantasy";
			break;

		case "singles":
			imageName = "singles";
			break;

		case "socializing" || "parties":
			imageNumber = imageRandomNumber(recoId, 3);
			imageName = `socializing/${imageNumber}`;
			break;

		case "support":
			imageName = "support";
			break;

		case "tech" || "science & technology":
			imageName = "tech";
			break;

		case "Spa & Hammam" ||
			"health & wellbeing" ||
			"health & wellness" ||
			"health-wellness" ||
			"health":
			imageNumber = imageRandomNumber(recoId, 2);
			imageName = `health-wellbeing/${imageNumber}`;
			break;

		case "women":
			imageName = "women";
			break;

		case "writing":
			imageName = "writing";
			break;

		case "concert" ||
			"concerts" ||
			"concerts & festivals" ||
			"music festival" ||
			"music festivals" ||
			"music-festivals" ||
			"music-and-concert":
			imageNumber = imageRandomNumber(recoId, 12);
			imageName = `concerts/${imageNumber}`;
			break;

		case "charity & causes":
			imageName = "charity-causes";
			break;

		case "school activities":
			imageName = "school-activities";
			break;

		case "seasonal & holiday":
			imageName = "seasonal-holiday";
			break;

		case "conferences":
			imageName = "conferences";
			break;

		case "miscellaneous-other" ||
			"other-other-events" ||
			"online-other-events" ||
			"Other Events" ||
			"other events" ||
			"Music: Other / Unknown" ||
			"music-other-unknown":
			imageNumber = imageRandomNumber(recoId, 2);
			imageName = `other/${imageNumber}`;
			break;

		case "bistros" ||
			"breakfast & brunch" ||
			"buffets" ||
			"delis" ||
			"gastronomic" ||
			"restaurants" ||
			"seafood" ||
			"steaks & grill":
			imageNumber = imageRandomNumber(recoId, 8);
			imageName = `other-restaurants/${imageNumber}`;
			break;

		case "comedy" || "Comedy":
			imageName = "comedy";
			break;

		case "music-classical" || "Music: Classical":
			imageName = "music-classical";
			break;

		case "local-events" || "Local Events":
			imageNumber = imageRandomNumber(recoId, 4);
			imageName = `local-event/${imageNumber}`;
			break;

		case "DIY & Crafts" ||
			"Hobbies & craft" ||
			"charity & causes" ||
			"fashion & beauty" ||
			"fashion and beauty" ||
			"food & drink" ||
			"food and drink" ||
			"lifestyle" ||
			"home & lifestyle" ||
			"live video" ||
			"networking" ||
			"online events" ||
			"photography" ||
			"sports & recreation" ||
			"sports & fitness":
			imageNumber = imageRandomNumber(recoId, 12);
			imageName = `events/${imageNumber}`;
			break;

		default: {
			imageNumber = imageRandomNumber(recoId, 4);
			switch (l1Tag) {
				case "events":
					imageName = `default/event/${imageNumber}`;
					break;

				case "restaurants":
					imageName = `default/restaurant/${imageNumber}`;
					break;

				case "hotels":
					imageName = `default/hotel/${imageNumber}`;
					break;

				case "visit":
					imageName = `default/visit/${imageNumber}`;
					break;

				default:
					imageName = "other-events";
					break;
			}
		}
	}

	return `https://api-ts.cruncho.co/cdn?path=plain/s3://client-specific-images/cover-images/${imageName}.jpg`;
};

/**
 *Compress only if it's cruncho_event api
 * @param key: Complete url
 * @param transformation: Imagekit transformation format
 * @param config: Environment configuration (staging, prod, ...)
 * @param api: image api
 */
export function compressedImageURL(
	key: string,
	transformation:
		| "n-guide_reco_card"
		| "n-guide_details"
		| "n-guide_featured"
		| "n-eventmanager_carousel"
		| "",
	config?: EnvConfig | EnvApiConfig,
	api?: AvailableApisEnum,
): string {
	let url;
	let size: string;
	let path;
	let copyKey = key ?? ""; // Sometimes for a weird reason key is undefined
	let width;
	let height;

	switch (transformation) {
		case "n-guide_reco_card":
			width = 400;
			height = 300;
			break;
		case "n-guide_details":
			width = 900;
			height = 510;
			break;
		case "n-guide_featured":
			width = 1400;
			height = 450;
			break;
		case "n-eventmanager_carousel":
			width = 700;
			height = 250;
			break;
		default:
			width = 900;
			height = 510;
			break;
	}

	switch (api) {
		case AvailableApisEnum.crunchoEvent:
			if (!config) return key;

			url = (copyKey as string).match(
				/(http[s]?:\/\/(eventmanager-.*).s3..*.amazonaws.com)\/(.*)/,
			);
			if (url) copyKey = url[3];
			else return key;

			size = `resize:fill:${width}:${height}/gravity:ce`;
			path = `${size}/plain/s3://${url[2]}/${encodeURIComponent(copyKey)}`;
			return `${config.tsApiRoot}cdn?path=${path}`;

		case AvailableApisEnum.google:
			size = `w${width}-h${height}-c`;
			if (copyKey.match(/\/s[0-9]*-w[0-9]*\//))
				return copyKey.replace(/\/s[0-9]*-w[0-9]*\/$/, `/${size}/`);
			if (copyKey.match(/=s[0-9]*-w[0-9]*$/))
				return copyKey.replace(/=s[0-9]*-w[0-9]*$/, `=${size}`);
			if (
				copyKey.match(
					/.*lh[0-9]{1}\.googleusercontent\.com\/p[laces]*\/[a-zA-Z0-9\_\-]+$/,
				)
			)
				return `${copyKey}=${size}`;
			return `${copyKey}&size=${size}`;

		case AvailableApisEnum.foursquare:
			return copyKey.replace(/\/original\//, `/${width}x${height}/`);

		case AvailableApisEnum.tripadvisor:
			switch (transformation) {
				case "n-guide_reco_card":
					return copyKey.replace("photo-o", "photo-s");
				default:
					return copyKey.replace("photo-s", "photo-o");
			}

		case AvailableApisEnum.yelp:
			/*
			Unreliable CDN, sometimes all sizes exist but not always.
			We keep using the image they provide as a default.
			*/
			return copyKey;

		case AvailableApisEnum.fork:
			return copyKey.replace(
				/w_[0-9]*,h_[0-9]*/,
				`w_${width},h_${height},c_fill,g_auto`,
			);

		case AvailableApisEnum.facebookEvent:
			if (!config) return key;

			url =
				copyKey.match(
					/(http[s]?:\/\/(imgproxy-cache.*).s3..*.amazonaws.com)\/(.*)/,
				) || copyKey.match(/(s3:\/\/(imgproxy-cache))\/(.*)/);

			if (url) copyKey = url[3];
			else return key;

			size = `resize:fill:${width}:${height}/gravity:ce`;
			path = `${size}/plain/s3://${url[2]}/${encodeURIComponent(copyKey)}`;
			return `${config.tsApiRoot}/cdn?path=${path}`;

		case AvailableApisEnum.songkick:
			switch (transformation) {
				case "n-guide_reco_card":
					size = "huge_avatar";
					break;
				case "n-guide_details":
					size = "col6";
					break;
				default:
					size = "huge_avatar";
					break;
			}
			return copyKey.replace("huge_avatar", `${size}`);

		default:
			return copyKey;
	}
}
