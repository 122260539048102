import { ERROR_CODES_MAP, Label } from "@cruncho/cruncho-shared-types";
import { enqueueNotification } from "reducers/notificationsReducer";
import { AppThunk } from "../configureStore";
import { createErrorNotification } from "../notificationCreators/errorMessageCreator";
import { CmsNotification } from "../notificationCreators/parseError";
import { createSuccessNotification } from "../notificationCreators/sucessNotificationCreator";

export function errorUpdateDestination(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Failed to update destination",
			error,
		);
		dispatch(enqueueNotification(notification));
	};
}

export function errorUpdateFeature(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Failed to update feature",
			error,
		);
		dispatch(enqueueNotification(notification));
	};
}

export function successUpdateLabels(labels: Label[]): AppThunk {
	return (dispatch) => {
		if (labels.length > 0) {
			const notification = createSuccessNotification(
				"Saved",

				`Labels ${labels.map((label) => label.text).join(" & ")} updated`,
			);

			dispatch(enqueueNotification(notification));
		}
	};
}

export function successUpdateFeature(): AppThunk {
	return (dispatch) => {
		const notification = createSuccessNotification("Saved", "Feature updated");
		dispatch(enqueueNotification(notification));
	};
}

export function errorUpdateArea(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Failed to create or update area",
			error,
		);
		dispatch(enqueueNotification(notification));
	};
}

export function successUpdateArea(): AppThunk {
	return (dispatch) => {
		const notification = createSuccessNotification("Saved", "Area updated");
		dispatch(enqueueNotification(notification));
	};
}

export function errorUpdateZoom(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification("Failed to update Zoom", error);
		dispatch(enqueueNotification(notification));
	};
}

export function successUpdateZoom(): AppThunk {
	return (dispatch) => {
		const notification = createSuccessNotification("Saved", "Zoom updated");
		dispatch(enqueueNotification(notification));
	};
}

export function errorCreateDestination(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Failed to create Destination",
			error,
		);
		dispatch(enqueueNotification(notification));
	};
}

export function successCreateDestination(): AppThunk {
	return (dispatch) => {
		const notification = createSuccessNotification(
			"Saved",
			"Destination created",
		);
		dispatch(enqueueNotification(notification));
	};
}

export function errorFetchCategories(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Failed To Load Raw Slugs",
			error,
		);
		dispatch(enqueueNotification(notification));
	};
}

export function successSaveChanges(): AppThunk {
	return (dispatch) => {
		const notification: CmsNotification = createSuccessNotification(
			"Recommendation Saved",
			"",
		);

		dispatch(enqueueNotification(notification));
	};
}

export function errorSaveReco(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification("Unable to save changes", error);
		dispatch(enqueueNotification(notification));
	};
}

export function successFetchReco(): AppThunk {
	return (dispatch) => {
		const notification: CmsNotification = createSuccessNotification(
			"Recommendation Loaded",
		);

		dispatch(enqueueNotification(notification));
	};
}

export function errorFetchReco(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Unable to load recommendation",
			error,
		);
		dispatch(enqueueNotification(notification));
	};
}

export function errorAddOwnPhoto(error: any): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification("Error uploading image", error);
		dispatch(enqueueNotification(notification));
	};
}

export function doNotDeleteDisplayedReview(): AppThunk {
	return (dispatch) => {
		const notification = createErrorNotification(
			"Error deleting the review",
			"You can't delete the displayed review of a recommendation (the one that appars on the preview of the recommendation). Select another displayed review for each language first by clicking on the flags and try again.",
		);
		dispatch(enqueueNotification(notification));
	};
}

export function createErrorDownloadDirectory() {
	const notification = createErrorNotification(
		"Error Dowloading CSV",
		"Most probably you were not granted access to this feature.",
	);
	return enqueueNotification(notification);
}

function succeededHideBatch(
	failed: number,
	succeeded: number,
): CmsNotification {
	const total = failed + succeeded;
	return {
		header: `${total} recommendations updated`,
		message: "",
		persist: false,
		details: "",
		variant: "success",
	};
}
function failedHideBatch(failed: number, succeeded: number): CmsNotification {
	const total = failed + succeeded;
	return {
		header: "Hide batch completed with errors",
		message: `${failed} out of ${total} failed`,
		variant: "error",
		persist: true,
		details: "",
	};
}

export function feedBackHideBatchCompleted(): AppThunk {
	return (dispatch, getState) => {
		const failed = getState().hideBatch.failed.length;
		const succeeded = getState().hideBatch.succeeded.length;

		const failedReasonList = getState().hideBatch.failed.map(
			(res) => res?.error?.response.data.message,
		);

		const notification: CmsNotification = failed
			? failedHideBatch(failed, succeeded)
			: succeededHideBatch(failed, succeeded);

		const recoOutsidePolygon = failedReasonList.some(
			(reason) => reason === ERROR_CODES_MAP.OUTSIDE_POLYGON,
		);

		if (recoOutsidePolygon) {
			notification.message = `${notification.message}, recommendation(s) are outside polygon`;
		}
		dispatch(enqueueNotification(notification));
	};
}
