import { z } from "zod";
import { appLanguageCodeSchema, AuthSources, userSchema } from "..";
import {
	quizQuestionCreateInputSchema,
	quizQuestionSchema,
	quizQuestionUpdateInputSchema,
} from "./quizQuestion";

// ########### //
// Quiz status //
// ########### //

export const QUIZ_STATUS = {
	DRAFT: "DRAFT",
	PLANNED: "PLANNED",
	WAITING_FOR_WINNER: "WAITING FOR WINNER",
	FINISHED: "FINISHED",
};
export const quizStatusSchema = z.nativeEnum(QUIZ_STATUS);
export type QuizStatusKeys = keyof typeof QUIZ_STATUS;
export type AvailableQuizStatus = typeof QUIZ_STATUS[QuizStatusKeys];
export function isQuizStatus(value: string): value is AvailableQuizStatus {
	return Object.values<string>(QUIZ_STATUS).includes(value);
}

// ########## //
// Quiz types //
// ########## //

export const QUIZ_TYPES = {
	CLASSIC: "CLASSIC",
} as const;
export type QuizTypesKeys = keyof typeof QUIZ_TYPES;
export type AvailableQuizTypes = typeof QUIZ_TYPES[QuizTypesKeys];

// ########### //
// Quiz themes //
// ########### //

export const QUIZ_THEMES = {
	DEFAULT: "DEFAULT",
} as const;
export type QuizThemesKeys = keyof typeof QUIZ_THEMES;
export type AvailableQuizThemes = typeof QUIZ_THEMES[QuizThemesKeys];

const quizTheme = z.object({
	primaryColor: z.string().optional(),
	darkerGradientColor: z.string().optional(),
	lighterGradientColor: z.string().optional(),
	secondaryColor: z.string().optional(),
	secondaryTextColor: z.string().optional(),
});

export type QuizTheme = z.infer<typeof quizTheme>;

// #### //
// Quiz //
// #### //

const baseQuizSchema = z.object({
	_id: z.string(),

	availableTranslations: appLanguageCodeSchema.array().optional(),

	destinationSlug: z.string(),

	endDate: z.date().optional(),

	image: z.string().array(),

	language: appLanguageCodeSchema,

	owner: userSchema,

	prize: z
		.object({
			name: z.string(),
			numberOfWinners: z.number().positive(),
		})
		.optional(),

	questions: quizQuestionSchema.array(),

	startDate: z.date().optional(),
	status: quizStatusSchema,

	theme: quizTheme,
	title: z
		.string({ required_error: "The title of the quiz should not be empty" })
		.min(1, "The title of the quiz should not be empty"),
	type: z.nativeEnum(QUIZ_TYPES),

	wantedLanguages: appLanguageCodeSchema.array(),
	winners: z.string().array().optional(),
});
export type BaseQuiz = z.infer<typeof baseQuizSchema>;

const languageSpecific = z.object({
	da: baseQuizSchema.partial(),
	de: baseQuizSchema.partial(),
	en: baseQuizSchema.partial(),
	"en-AU": baseQuizSchema.partial(),
	"en-US": baseQuizSchema.partial(),
	es: baseQuizSchema.partial(),
	fr: baseQuizSchema.partial(),
	it: baseQuizSchema.partial(),
	nb: baseQuizSchema.partial(),
	nl: baseQuizSchema.partial(),
	pt: baseQuizSchema.partial(),
	ro: baseQuizSchema.partial(),
	ru: baseQuizSchema.partial(),
	sv: baseQuizSchema.partial(),
	zh_Hans: baseQuizSchema.partial(),
	zh_Hant: baseQuizSchema.partial(),
});
const quizSchema = baseQuizSchema.merge(languageSpecific);
export type Quiz = z.infer<typeof quizSchema>;

/**
 * Describe the quiz object returned after an API call.
 *
 * The main difference is that dates are string.
 */
export type APIQuizResponse = Omit<Quiz, "startDate" | "endDate"> & {
	startDate?: string;
	endDate?: string;
};

export function convertAPIQuizResponseToQuiz({
	startDate,
	endDate,
	...quiz
}: APIQuizResponse): Quiz {
	return {
		...quiz,
		...(startDate ? { startDate: new Date(startDate) } : {}),
		...(endDate ? { endDate: new Date(endDate) } : {}),
	};
}

export const quizCreateInputSchema = baseQuizSchema
	.omit({
		_id: true,
		winners: true,
		status: true,
	})
	.extend({
		questions: quizQuestionCreateInputSchema.array(),
		startDate: z.string().optional(),
		endDate: z.string().optional(),
	});
export type QuizCreateInput = z.infer<typeof quizCreateInputSchema>;

export const quizWithoutOwnerCreateInputSchema = quizCreateInputSchema.omit({
	owner: true,
});
export type QuizWithoutOwnerCreateInput = z.infer<
	typeof quizWithoutOwnerCreateInputSchema
>;

export const quizUpdateInputSchema = baseQuizSchema
	.omit({
		owner: true,
		status: true,
		_id: true,
	})
	.extend({
		questions: quizQuestionUpdateInputSchema.array(),
		startDate: z.string().optional(),
		endDate: z.string().optional(),
	});
export type QuizUpdateInput = z.infer<typeof quizUpdateInputSchema>;

// ############## //
// Quiz JWT Token //
// ############## //

export const quizJWTPayloadSchema = z.object({
	ids: z.string().array(),
});
export type QuizJWTPayload = z.infer<typeof quizJWTPayloadSchema>;

// ################## //
// Quiz answers types //
// ################## //

export const singleAnswerCreateInputSchema = z.object({
	questionId: z.string(),
	answer: z.union([z.number().array(), z.tuple([z.string()])]),
});

export type SingleAnswerCreateInput = z.infer<
	typeof singleAnswerCreateInputSchema
>;

export type SingleAnswer = {
	answer: [string] | number[]; // Open answer | MCQ/YesNo
	questionId: string;
	score?: number;
};

export type QuizParticipation = {
	_id: string;
	answers: SingleAnswer[];
	playerId?: string;
	quizId: string;
};

export type QuizPlayer = {
	_id: string;
	sub: string;
	email?: string;
	locale?: string;
	logins: string[];
	name?: string;
	pictureUrl?: string;
	source: AuthSources;
};

export type QuizPlayerWithScore = QuizPlayer & {
	score: number;
};

// Zod schema and associated type when filtering quizzes
export const quizFiltersParamsSchema = z.object({
	fromDate: z.date().optional(),
	toDate: z.date().optional(),
	status: z.string().optional().describe("Quiz status(es) to select"),
});
export type QuizFilters = z.infer<typeof quizFiltersParamsSchema>;
