import { RecoNameQuery, SearchReco } from "@cruncho/cruncho-shared-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface RecoNameSearchState {
	async: AsyncCall;
	recos: SearchReco[];
	query?: RecoNameQuery;
}

export interface RecoNameSearchResult {
	_id: string;
	name: string;
	hide: boolean;
}

const initialState: RecoNameSearchState = {
	async: initAsync(),
	recos: [],
};

const recoNameSearchSlice = createSlice({
	name: "recoNameSearch",
	initialState,
	reducers: {
		requestRecosByName(state, action: PayloadAction<RecoNameQuery>) {
			state.query = action.payload;
			state.async = startAsync();
		},
		receiveRecosByName(
			state,
			action: PayloadAction<{ data?: SearchReco[]; error?: any }>,
		) {
			if (action.payload.data) state.recos = action.payload.data;
			state.async = handleResponse(action.payload);
		},
	},
});

export const { requestRecosByName, receiveRecosByName } =
	recoNameSearchSlice.actions;

export default recoNameSearchSlice.reducer;
