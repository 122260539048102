import { PopulatedDestination } from "@cruncho/cruncho-shared-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const destinationConfigSlice = createSlice({
	name: "destinationConfig",
	initialState: null as PopulatedDestination | null,
	reducers: {
		receiveDestinationConfig(
			_state,
			action: PayloadAction<PopulatedDestination | null>,
		) {
			return action.payload;
		},
	},
});

export const { receiveDestinationConfig } = destinationConfigSlice.actions;

export default destinationConfigSlice.reducer;
