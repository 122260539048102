import { add } from "date-fns";

export function createTripAdvisorDateRangeFromPartialDateRange(dateRange: {
	from: Date;
	to?: Date;
}) {
	const fromDate = new Date(dateRange.from);

	return {
		from: fromDate,
		// Arbitrary add 3 days if no end date
		to: dateRange.to ? new Date(dateRange.to) : add(fromDate, { days: 3 }),
	};
}
