import { z } from "zod";
import { currencyCodeSchema } from "../currencies";

const fieldsEnum = z.enum([
	"phone",
	"company",
	"specialDiet",
	"maxNumberOfParticipants",
	"price",
]);

export enum RSVPStatus {
	CLOSED = "CLOSED",
	FULL = "FULL",
	OPEN = "OPEN",
	DISABLED = "DISABLED",
}

export const priceSchema = z
	.object({
		id: z.string(),
		price: z.number().optional(),
		currency: currencyCodeSchema.default("SEK").optional(),
		label: z.string().optional(),
		// if a price option is booked by someone, it cannot be deleted
		booked: z.boolean().default(false),
		bookedAt: z.string().or(z.date()).optional(),
		// if an price option is archived, it will not show for user
		archived: z.boolean().default(false),
		archivedAt: z.string().or(z.date()).optional(),
	})
	.refine((data) => data.price !== undefined && data.price >= 0, {
		message: "Price must be a positive number",
		path: ["price"],
	})
	.refine((data) => data.currency !== undefined, {
		message: "Currency is required",
		path: ["currency"],
	})
	.refine((data) => data.label !== undefined, {
		message: "Label is required",
		path: ["label"],
	})
	.refine((data) => data.label !== undefined && data.label.length <= 20, {
		message: "Label must be 20 characters or less",
		path: ["label"],
	})
	.refine((data) => /^[^\s!@#$%^&*,?":{}|<>]*$/.test(data.label || ""), {
		message: "Label must not contain special characters",
		path: ["label"],
	});

export const rsvpSchema = z
	.object({
		allowSeveralPeoplePerAnswer: z.boolean().default(false),
		confirmationEmailText: z.string().optional(),
		enabled: z.literal(true),
		enabledFields: z.record(fieldsEnum, z.boolean()),
		gdprText: z.string().optional(),
		maxNumberOfParticipants: z
			.number()
			.refine((input) => input >= 0)
			.optional(),
		moreInformationText: z.string().optional(),
		priceOptions: z.array(priceSchema).optional(),
		registrationOpenUntil: z.string().or(z.date()).optional(),
		specialDiet: z.string().optional(),
		selectAllOccurrences: z
			.boolean()
			.describe(
				"If there's more than one occurrence of the event then decide if saving a place will save a place in all occurrences",
			),
		status: z.nativeEnum(RSVPStatus).default(RSVPStatus.OPEN),
	})
	.or(z.object({ enabled: z.literal(false) }));

export type PriceOption = z.infer<typeof priceSchema>;

export type RSVP = z.infer<typeof rsvpSchema>;
