import { CategoryTree } from "@cruncho/cruncho-shared-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface AllCategoriesState {
	async: AsyncCall;
	categories: CategoryTree[];
}

const initialState: AllCategoriesState = {
	async: initAsync(),
	categories: [],
};

const allCategoriesSlice = createSlice({
	name: "allCategories",
	initialState,
	reducers: {
		requestAllCategories(state) {
			state.async = startAsync();
		},
		receiveAllCategories(
			state,
			action: PayloadAction<{ data?: CategoryTree[]; error?: any }>,
		) {
			state.categories = [...(action.payload.data ?? [])];
			state.async = handleResponse(action.payload);
		},
	},
});

export const { requestAllCategories, receiveAllCategories } =
	allCategoriesSlice.actions;

export default allCategoriesSlice.reducer;
