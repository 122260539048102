import { z } from "zod";
import { positionSchema } from "./destination";
import {
	REJECTED,
	POSTED,
	PENDING,
	DRAFT,
	DELETED,
	ERROR_POSTING,
	UNPAID,
} from "./eventmanager/liveEvent";

export const listEventSchema = z.object({
	areas: positionSchema.array().array().optional(),
	categories: z.string().array().optional(),
	destinationSlug: z.string(),
	endDate: z.string().optional(), // Stringified ISO date
	fields: z.string().array().optional(),
	hideOngoingEvents: z.boolean().optional(),
	isModerator: z.boolean(),
	limit: z.number().default(500),
	groups: z.string().array().optional(),
	payed: z.boolean().optional(),
	postOptions: z.string().array().optional(),
	sortDate: z.enum(["asc", "desc"]).optional(),
	scrapers: z.boolean().optional(),
	startDate: z.string().optional(), // Stringified ISO date
	status: z
		.enum([
			REJECTED,
			POSTED,
			PENDING,
			DRAFT,
			DELETED,
			ERROR_POSTING,
			UNPAID,
			"all",
		])
		.array()
		.optional(),
	userId: z.string(),
	text: z.string().optional(),
	transactionId: z.string().optional(),
	venues: z.string().array().optional(),
});

export type ListEventBody = z.infer<typeof listEventSchema>;

export const listUsersBodySchema = z.object({
	destinationSlug: z.string(),
	limit: z.number().optional(),
	startDate: z.string().optional(),
	endDate: z.string().optional(),
	text: z.string().optional(),
	// TODO: what is nextToken ?
	nextToken: z.string().optional(),
});

export type ListUsersBody = z.infer<typeof listEventSchema>;

export const listGroupsSchema = z.object({
	destinationSlug: z.string(),
	userId: z.string().optional(),
});

export type ListGroupsQuery = z.infer<typeof listGroupsSchema>;

export interface ListVenuesBody {
	destinationSlug: string;
	placeId?: string;
}

export interface ErrorMessage {
	code: string;
	message: string;
	name: string;
	stack: string;
}
