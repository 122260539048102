import { combineReducers } from "redux";
import adminGroup from "./adminGroupReducer";
import allCategories from "./allCategoriesReducer";
import decodedHash from "./decodedHashReducer";
import destinationConfig from "./destinationConfigReducer";
import hideBatch from "./hideBatchReducer";
import isAuthHeaderSet from "./isAuthHeaderSetReducer";
import myRecos from "./myRecosReducer";
import notifications from "./notificationsReducer";
import categories from "./propertyReducers/categoriesReducer";
import hideSetting from "./propertyReducers/hideSettingReducer";
import pinnedSetting from "./propertyReducers/isPinnedReducer";
import sponsoredSetting from "./propertyReducers/sponsoredSettingReducer";
import viatorData from "./propertyReducers/viatorDataReducer";
import recoNameSearch from "./recoNameSearchReducer";
import saveButton from "./saveButtonReducer";
import SaveRecommendation from "./saveRecommendationReducer";
import selectedReco from "./selectedRecoReducer";
import user from "./userReducer";

const rootReducer = combineReducers({
	adminGroup,
	allCategories,
	categories,
	decodedHash,
	destinationConfig,
	hideBatch,
	hideSetting,
	isAuthHeaderSet,
	myRecos,
	notifications,
	pinnedSetting,
	recoNameSearch,
	saveButton,
	SaveRecommendation,
	selectedReco,
	sponsoredSetting,
	user,
	viatorData,
});

export default rootReducer;
