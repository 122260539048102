import { AppLanguageCode, StructuredReco } from "@cruncho/cruncho-shared-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import * as patcher from "jsondiffpatch";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface SelectedRecoState {
	isLoaded: boolean;
	id: number;
	hash: {
		async: AsyncCall;
		hash: string;
	};
	originalReco: {
		async: AsyncCall;
		reco: StructuredReco | false;
	};
	savedModifiedReco: {
		async: AsyncCall;
		reco: StructuredReco | false;
	};
	unsavedModifiedReco: StructuredReco | false;
	diffOriginalSaved: any;
	diffSavedUnsaved: any;
}

const selectedRecoInitialState: SelectedRecoState = {
	isLoaded: false,
	id: 0,
	hash: { async: initAsync(), hash: "" },
	originalReco: { async: initAsync(), reco: false },
	savedModifiedReco: { async: initAsync(), reco: false },
	unsavedModifiedReco: false,
	diffOriginalSaved: {},
	diffSavedUnsaved: {},
};

function createDiff(from: any, to: any) {
	let diff;
	try {
		diff = patcher.diff(from, to) || {};
	} catch (error) {
		console.error("initial diff failed:", error);
	}
	if (!diff) {
		const encodedFields: string[] = [];
		Object.keys(from).forEach((key) => {
			// Try the single diff between the fields and find errors, if it's a string then encode it
			// since this is where we first found the error where Unicode characters (\uD83D) were messing up the diff
			try {
				patcher.diff({ [key]: from[key] }, { [key]: to[key] });
			} catch (singleDiffError) {
				console.groupCollapsed("singleDiffError");
				console.error("key", key);
				console.error("error", singleDiffError);
				console.log({ from: from[key], to: to[key] });
				console.groupEnd();
				if (typeof from[key] === "string") {
					from[key] = encodeURI(from[key]);
					to[key] = encodeURI(to[key]);
					encodedFields.push(key);
				}
			}

			// Try patching with the new encoded value, if this fails then log the field and delete it to avoid reco not loading
			try {
				patcher.diff({ [key]: from[key] }, { [key]: to[key] });
			} catch (singleDiffEncodedError) {
				console.groupCollapsed("singleDiffError");
				console.error("key", key);
				console.error("error", singleDiffEncodedError);
				console.log({ from: from[key], to: to[key] });
				console.groupEnd();
				delete from[key];
				delete to[key];
			}
		});
	}
	diff = patcher.diff(from, to) || {};

	return diff;
}

const selectedRecoSlice = createSlice({
	name: "selectedReco",
	initialState: selectedRecoInitialState,
	reducers: {
		selectReco(state, action: PayloadAction<number>) {
			state.id = action.payload;
		},
		requestOriginalReco(state) {
			state.originalReco.async = startAsync();
		},
		receiveOriginalReco(
			state,
			action: PayloadAction<{ data?: StructuredReco; error?: any }>,
		) {
			if (action.payload.data) state.originalReco.reco = action.payload.data;
			state.originalReco.async = handleResponse(action.payload);
		},
		requestModifiedReco(state) {
			state.savedModifiedReco.async = startAsync();
		},
		receiveModifiedReco(
			state,
			action: PayloadAction<{ data?: StructuredReco; error?: any }>,
		) {
			if (action.payload.data) state.savedModifiedReco.reco = action.payload.data;
			state.savedModifiedReco.async = handleResponse(action.payload);
		},
		requestHash(state) {
			state.hash.async = startAsync();
		},
		receiveHash(state, action: PayloadAction<{ data?: string; error?: any }>) {
			if (action.payload.data) state.hash.hash = action.payload.data;
			state.hash.async = handleResponse(action.payload);
		},
		recoLoaded(state) {
			const diff = createDiff(
				state.originalReco.reco,
				state.savedModifiedReco.reco,
			);
			state.diffOriginalSaved = diff;
			state.unsavedModifiedReco = state.savedModifiedReco.reco;
			state.isLoaded = true;
		},
		recoUpdate(state, action: PayloadAction<StructuredReco | false>) {
			state.unsavedModifiedReco = action.payload;
			state.diffSavedUnsaved = createDiff(
				state.savedModifiedReco.reco,
				action.payload,
			);
		},
		setDisplayCommentForLanguage(
			state,
			action: PayloadAction<{ language: AppLanguageCode; displayComment: string }>,
		) {
			if (state.unsavedModifiedReco) {
				let langNode = state.unsavedModifiedReco[action.payload.language];
				if (typeof langNode === "undefined") {
					langNode = {};
				}
				const modifiedLangNode = Object.assign(langNode, {
					displayComment: action.payload.displayComment,
				});

				const modifiedReco = Object.assign(state.unsavedModifiedReco, {
					[action.payload.language]: modifiedLangNode,
				});

				state.unsavedModifiedReco = modifiedReco;
				state.diffSavedUnsaved = createDiff(
					state.savedModifiedReco.reco,
					modifiedReco,
				);
			}
		},
	},
});

export const {
	selectReco,
	requestOriginalReco,
	receiveOriginalReco,
	requestModifiedReco,
	receiveModifiedReco,
	requestHash,
	receiveHash,
	recoLoaded,
	recoUpdate,
	setDisplayCommentForLanguage,
} = selectedRecoSlice.actions;

export default selectedRecoSlice.reducer;
