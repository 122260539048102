import { fetchRecoByHash } from "../actions";

const allowedOrigins = [
	".cruncho.co",
	".cruncho.com",
	".cruncho.in",
	".cruncho.md",
];

export function sendMessage(hash: string) {
	if (window.opener) {
		window.opener.postMessage(hash, document.referrer);
		console.log(`Has opener, message Sent: ${hash}`);
	} else {
		throw new Error("No opener, message not sent");
	}
}

export const messageReceiver = (store: any) =>
	function receiveMessage(event: any) {
		allowedOrigins.forEach((allowedOrigin) => {
			if (event.origin.indexOf(allowedOrigin) !== -1) {
				const message = event.data;
				if (message && typeof message === "string" && message.length < 8) {
					store.dispatch(fetchRecoByHash(event.data));
				}
			}
		});
	};
