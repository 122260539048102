import { z } from "zod";
import { latLngSchema, areaSchema } from "../destination";
import { l1Schema } from "../recommendation/properties";
import { SearchReco, baseRecoSchema } from "../recommendation/recommendation";
import { timeZonesSchema } from "../timezones";

/**
 * Provides information of the particular user doing the request
 */
const userContextSchema = z.object({
	alreadyViewedIds: z.string().array().default([]),
	likedRecos: baseRecoSchema.array().default([]),
	location: latLngSchema.optional(),
	visitedCategories: z.string().array().default([]),
});

/**
 * Provides information about the recommendation currently being seen
 * by the user
 */
const recommendationContextSchema = z.object({
	id: z.string(),
	l1: l1Schema.optional(),
	l2: z.string().array(),
	l3: z.string().array(),
	location: latLngSchema.optional(),
});

/**
 * Provides information about the current state of the page the user
 * is in. (if he's looking at all the listing, if he's looking into a
 * particular category)
 */
const pageContextSchema = z.object({
	clientTime: z.string().regex(/[0-9]{2}:[0-9]{2}/),
	ip: z.string(),
	destinationSlug: z.string(),
	l1: z.string().optional(),
	l2: z.string().optional(),
	l3: z.string().optional(),
	location: latLngSchema.optional(),
});

export const apiQuerySchema = z.object({
	userContext: userContextSchema,
	recoContext: recommendationContextSchema,
	pageContext: pageContextSchema,
});

export const categoryApiQuerySchema = z
	.object({
		area: areaSchema.optional(),
		bookable: z.boolean().optional(),
		endDate: z.string().optional(),
		l2: z.string().array().optional(),
		l3: z.string().array().optional(),
		openNow: z.boolean().optional(),
		startDate: z.string().optional(),
		timezone: timeZonesSchema.optional(),
		apiProvider: z.string().optional(),
	})
	.merge(apiQuerySchema)
	.refine(
		(obj) => (!!obj.l2 && obj.l2?.length > 0) || (!!obj.l3 && obj.l3?.length > 0),
		"Either l2 or l3 should contain category slugs",
	);

export const eventQuerySchema = z
	.object({
		area: areaSchema.optional(),
		bookable: z.boolean().optional(),
		endDate: z.string().optional(),
		l2: z.string().array().optional(),
		l3: z.string().array().optional(),
		online: z.boolean().optional(),
		free: z.boolean().optional(),
		organizer: z.string().optional(),
		startDate: z.string(),
		timezone: timeZonesSchema,
		venue: z.string().optional(),
		apiProvider: z.string().optional(),
	})
	.merge(apiQuerySchema);

export const baseRecoQuerySchema = z.object({
	area: areaSchema.optional(),
	apiProvider: z.string().optional(),
	bookable: z.boolean().optional().describe("Filter bookable recommendations"),
	endDate: z.string().optional(),
	free: z.boolean().optional().describe("Filter free recommendations"),
	handpicked: z.boolean().optional(),
	l2: z.array(z.string()).optional(),
	l3: z
		.array(z.string())
		.optional()
		.describe("Filter recommendations by category"),
	format: z.string().optional(),
	lastUpdated: z.string().optional(),
	online: z.boolean().optional().describe("Filter online recommendations"),
	openNow: z
		.boolean()
		.optional()
		.describe("Filter recommendations with opening hours"),
	organizer: z.string().optional(),
	startDate: z.string().optional(),
	timezone: timeZonesSchema.optional(),
	venue: z.string().optional().describe("Filter recommendations by venue"),
});

export const recoQuerySchema = baseRecoQuerySchema.merge(
	z.object({
		pageContext: pageContextSchema,
		location: latLngSchema.optional(),
	}),
);

export type CategoryApiQuery = z.infer<typeof categoryApiQuerySchema>;
export type EventApiQuery = z.infer<typeof eventQuerySchema>;
export type RecoApiQuery = z.infer<typeof recoQuerySchema>;
export type ApiQuery = z.infer<typeof apiQuerySchema>;

const l1ExtraFiltersSchema = z
	.object({
		l3Filters: z.string().array(),
		onlyBookable: z.boolean(),
		openNow: z.boolean(),
		covidSafe: z.boolean(),
		online: z.boolean(),
		free: z.boolean(),
		isPined: z.boolean(),
		venue: z.string(),
		organizer: z.string(),
		exclude: z.boolean(),
	})
	.partial();

export const extraFiltersSchema = z.record(
	l1Schema,
	l1ExtraFiltersSchema.optional(),
);

export const cruncherQuerySchema = z.object({
	city: z.string(),
	fromDate: z.string(),
	toDate: z.string().optional(),
	extraFilters: extraFiltersSchema.optional(),
	minScore: z.number().optional(),
	size: z.number().optional(),
	pageIndex: z.number().optional(),
	geopolygon: z.array(latLngSchema).optional(),
	area: areaSchema.optional(),
});

export const cruncherApiQuerySchema = z.object({
	pageContext: pageContextSchema,
	query: cruncherQuerySchema,
	searchString: z.string(),
	l1: z.string(),
	l2: z.string(),
	allL3s: z.string(),
	l3: z.string(),
});

export type L1ExtraFilters = z.infer<typeof l1ExtraFiltersSchema>;
export type ExtraFilters = z.infer<typeof extraFiltersSchema>;
export type CruncherQuery = z.infer<typeof cruncherQuerySchema>;
export type CruncherApiQuery = z.infer<typeof cruncherApiQuerySchema>;

export type CrunchoQueryResult = Pick<
	SearchReco,
	"l1" | "geometry" | "name"
> & { id: string }; // The id in the result is encoded
