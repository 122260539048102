import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface HideSettingState {
	hide: boolean;
	hideReason?: string;
}

const hideSettingSlice = createSlice({
	name: "hideSetting",
	initialState: { hide: false } as HideSettingState,
	reducers: {
		updateHide(state, action: PayloadAction<boolean>) {
			state.hide = action.payload;
		},
		updateHideReason(state, action: PayloadAction<string>) {
			state.hideReason = action.payload;
		},
	},
});

export const { updateHide, updateHideReason } = hideSettingSlice.actions;

export default hideSettingSlice.reducer;
