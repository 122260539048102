import { z } from "zod";

export const CURRENCY_CODES = [
	"AUD",
	"BYN",
	"CAD",
	"CRC",
	"DKK",
	"EUR",
	"GBP",
	"NOK",
	"SEK",
	"USD",
] as const;

export const CURRENCY_SYMBOLS = [
	"AUD",
	"BYN",
	"CAD",
	"CRC",
	"DKK",
	"€",
	"GBP",
	"NOK",
	"SEK",
	"$",
] as const;

/**
 *
 * Standardized Currency code
 */
export const currencyCodeSchema = z.enum(CURRENCY_CODES);
export const currencySymbolSchema = z.enum(CURRENCY_SYMBOLS);

export type CurrencyCode = z.infer<typeof currencyCodeSchema>;
export type CurrencySymbol = z.infer<typeof currencySymbolSchema>;

export const CURRENCY_CODES_MAP: Record<CurrencyCode, CurrencySymbol> = {
	AUD: "AUD",
	BYN: "BYN",
	CAD: "CAD",
	CRC: "CRC",
	DKK: "DKK",
	EUR: "€",
	GBP: "GBP",
	NOK: "NOK",
	SEK: "SEK",
	USD: "$",
};
