import { CmsNotification } from "./parseError";

export function createSuccessNotification(
	header: string,
	message: string = "",
	details: string = "",
): CmsNotification {
	return {
		header,
		message,
		details,
		variant: "success",
		persist: !!(message || details),
	};
}

export default createSuccessNotification;
