import CssBaseline from "@mui/material/CssBaseline";
import {
	createTheme,
	StyledEngineProvider,
	ThemeProvider,
} from "@mui/material/styles";
import Logon from "components/LogonComponent";
import { SnackbarProvider } from "notistack";
import React, { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import MainNav from "./components/MainNavComponent";
import Notifications from "./components/notificationComponents/NotificationsComponent";
import { cmsStyleClasses } from "./components/styles/cmsStyles";
import "./css/GlobalCss.css";

const theme = createTheme({
	palette: {
		primary: {
			main: "#3f51b5",
		},
		secondary: {
			main: "#f50057",
		},
	},
});

const BasicInfo = lazy(
	() =>
		import(
			/* webpackChunkName: "BasicInfo" */ "components/basicInfo/BasicInfoComponent"
		),
);
const MasterMode = lazy(
	() => import(/* webpackChunkName: "MasterMode" */ "components/MasterMode"),
);
const PhotosComponent = lazy(
	() =>
		import(
			/* webpackChunkName: "PhotosComponent" */ "components/PhotosComponent"
		),
);
const Reviews = lazy(
	() => import(/* webpackChunkName: "Reviews" */ "components/ReviewsComponent"),
);
const Search = lazy(
	() =>
		import(
			/* webpackChunkName: "Search" */ "components/searchComponents/SearchComponent"
		),
);
const Videos = lazy(
	() => import(/* webpackChunkName: "Videos" */ "components/videosComponent"),
);
const TranslationFRComponent = lazy(
	() =>
		import(/* webpackChunkName: "Videos" */ "components/TranslationFRComponent"),
);
const Categories = lazy(
	() =>
		import(
			/* webpackChunkName: "Categories" */ "components/categories/CategoriesComponent"
		),
);
const Create = lazy(
	() => import(/* webpackChunkName: "Create" */ "components/CreateComponent"),
);
const LabelsComponent = lazy(
	() => import(/* webpackChunkName: "Labels" */ "components/LabelsComponent"),
);
const Curation = lazy(
	() =>
		import(
			/* webpackChunkName: "Curation" */ "components/curationComponents/CurationComponent"
		),
);

export const paths = {
	root: "/",
	basic: "/basic",
	photos: "/photos",
	videos: "/videos",
	translationFR: "/translationFR",
	mastermode: "/mastermode",
	reviews: "/reviews",
	logon: "/logon",
	search: "/search",
	categories: "/categories",
	create: "/create",
	labels: "/labels",
	curation: "/curation",
};

const App = () => (
	<Router>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<SnackbarProvider maxSnack={4} autoHideDuration={1800}>
					<div className={cmsStyleClasses.root}>
						<CssBaseline />
						<Notifications />
						<MainNav />
						<main className={cmsStyleClasses.content}>
							<Suspense fallback={<div>Loading...</div>}>
								<Routes>
									<Route path={paths.logon} element={<Logon />} />
									<Route path={paths.root} element={<BasicInfo />} />
									<Route path={paths.basic} element={<BasicInfo />} />
									<Route path={paths.photos} element={<PhotosComponent />} />
									<Route path={paths.videos} element={<Videos />} />
									<Route
										path={paths.translationFR}
										element={<TranslationFRComponent />}
									/>
									<Route path={paths.mastermode} element={<MasterMode />} />
									<Route path={paths.reviews} element={<Reviews />} />
									<Route path={paths.search} element={<Search />} />
									<Route path={paths.categories} element={<Categories />} />
									<Route path={paths.create} element={<Create />} />
									<Route path={paths.labels} element={<LabelsComponent />} />
									<Route path={paths.curation} element={<Curation />} />
								</Routes>
							</Suspense>
						</main>
					</div>
				</SnackbarProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</Router>
);
export default App;
