import { EnvApiConfig } from "@cruncho/cruncho-shared-types";
import axios from "axios";

const local = process.env.REACT_APP_LOCAL_DOMAIN ?? "";

export const configs: Record<string, EnvApiConfig> = {
	development_api_md: {
		tsApiRoot: "http://api-ts.cruncho.md:3000/",
		cachedTsApiRoot: "http://api-ts.cruncho.md:3000/",
		path: /\.md/,
	},
	production: {
		tsApiRoot: "https://api-ts-nc.cruncho.at/",
		cachedTsApiRoot: "https://api-ts.cruncho.at/",
		path: /\.com/,
	},
	staging: {
		tsApiRoot: "https://api-ts-nc.cruncho.in/",
		cachedTsApiRoot: "https://api-ts.cruncho.in/",
		path: /\.in/,
	},
	ngrok: {
		// Update with the current one
		tsApiRoot: "https://cb023fbb.ngrok.io/",
		cachedTsApiRoot: "https://api-ts.cruncho.in/",
		path: /\.io/,
	},
	localhost: {
		tsApiRoot: "http://localhost:3000/",
		cachedTsApiRoot: "http://localhost:3000/",
		path: /localhost/,
	},
	local: {
		tsApiRoot: `http://api-ts.cruncho.${local}:3000/`,
		cachedTsApiRoot: `http://api-ts.cruncho.${local}:3000/`,
		path: new RegExp(`.${local}`),
	},
};

function setBackend() {
	const url = window.location.host;
	let tsApi = "https://api-ts-nc.cruncho.at/";
	let cachedTsApi = "https://api-ts.cruncho.at/";
	Object.entries(configs).forEach(([env, config]) => {
		const { tsApiRoot, path, cachedTsApiRoot } = config;

		// skip if the environment is local but REACT_APP_LOCAL_DOMAIN is empty
		const shouldSkipLocal = env === "local" && local === "";
		if (shouldSkipLocal) {
			return;
		}

		if (url.match(path)) {
			tsApi = tsApiRoot;
			cachedTsApi = cachedTsApiRoot;
		}
	});
	console.log("api used", tsApi);
	return { tsApi, cachedTsApi };
}

export const apiTsRoot = `${setBackend().tsApi}`;
export const cachedApiTsRoot = `${setBackend().cachedTsApi}`;
export const adminApiPath = "cms";

export const apiTsInstance = axios.create({
	baseURL: cachedApiTsRoot,
});
export const cachedApiTsInstance = axios.create({
	baseURL: cachedApiTsRoot,
});
