import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { parseLogonQueryString } from "../actions";

interface ILogonProps extends ConnectedProps<typeof connector> {}
const Logon = ({ handleQueryString }: ILogonProps) => {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		handleQueryString(location.search);
		navigate("/");
	}, [handleQueryString, navigate, location.search]);

	return <div>Logon</div>;
};

const mapDispatchToProps = {
	handleQueryString: parseLogonQueryString,
};

const connector = connect(null, mapDispatchToProps);

export default connector(Logon);
