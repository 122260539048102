import { createSlice } from "@reduxjs/toolkit";

export interface SaveButtonState {
	visible: boolean;
	enabled: boolean;
}

const saveButtonSlice = createSlice({
	name: "saveButton",
	initialState: { visible: true, enabled: true } as SaveButtonState,
	reducers: {
		hideSaveButton(state) {
			state.visible = false;
		},
		showSaveButton(state) {
			state.visible = true;
		},
	},
});

export const { hideSaveButton, showSaveButton } = saveButtonSlice.actions;

export default saveButtonSlice.reducer;
