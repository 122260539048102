import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import IconButton from "@mui/material/IconButton";
import SnackbarContent from "@mui/material/SnackbarContent";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import React, { useState } from "react";
import { CmsNotification } from "../../notificationCreators/parseError";
import { cmsStyleClasses } from "../styles/cmsStyles";

export interface SnackBarWrapperProps {
	onClose: () => void;
	parsedMessage: CmsNotification;
	key: any;
}

const variantIcon: any = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
};

export default function SnackbarWrapper(props: SnackBarWrapperProps) {
	const { parsedMessage, onClose, key } = props;
	const Icon = variantIcon[parsedMessage.variant];
	const [openDetails, setOpenDetails] = useState(false);

	return (
		<div key={key}>
			{
				<SnackbarContent
					className={cmsStyleClasses[`snack_${parsedMessage.variant}`]}
					aria-describedby="client-snackbar"
					message={
						<span
							id="client-snackbar"
							aria-labelledby="clientSnackbar"
							className={cmsStyleClasses.snack_message}
						>
							<Icon
								className={clsx(
									cmsStyleClasses.snack_icon,
									cmsStyleClasses.snack_iconVariant,
								)}
							/>
							<div>
								<Typography variant="body1">{parsedMessage.header}</Typography>

								{parsedMessage.message && (
									<Typography variant="body2">{parsedMessage.message}</Typography>
								)}
								{parsedMessage.details &&
									(openDetails ? (
										<Typography variant="caption">{parsedMessage.details}</Typography>
									) : (
										<div
											style={{ color: "white", cursor: "pointer", fontSize: "0.75rem" }}
											onClick={() => setOpenDetails(true)}
										>
											Show Details
										</div>
									))}
							</div>
						</span>
					}
					action={[
						<IconButton
							key="close"
							aria-labelledby="close"
							color="inherit"
							onClick={onClose}
							size="large"
						>
							<CloseIcon className={cmsStyleClasses.snack_icon} />
						</IconButton>,
					]}
				/>
			}
		</div>
	);
}
