import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface SaveRecommendationState {
	async: AsyncCall;
}

const selectedRecoInitialState: SaveRecommendationState = {
	async: initAsync(),
};

const saveRecommendationSlice = createSlice({
	name: "SaveRecommendation",
	initialState: selectedRecoInitialState,
	reducers: {
		saveChanges(state) {
			state.async = startAsync();
		},
		receiveSaveConfirmation(state, action: PayloadAction<{ error?: any }>) {
			state.async = handleResponse(action.payload);
		},
	},
});

export const { saveChanges, receiveSaveConfirmation } =
	saveRecommendationSlice.actions;

export default saveRecommendationSlice.reducer;
