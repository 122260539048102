import { Position } from "@cruncho/cruncho-shared-types";
/**
 * Convert an array of coordinates tuple to an array of objects
 * Careful, the order is [longitude, latitude]
 * @returns
 */
export const positionToLatLng = ({
	lng,
	lat,
}: Position): google.maps.LatLngLiteral => ({
	lat,
	lng,
});

/**
 * Convert a google polygon to an array of [longitude, latitude]
 */
export const googlePolygonToPositions = (
	googleLatLng: google.maps.Polygon,
): Position[] =>
	googleLatLng
		.getPath()
		.getArray()
		.map((point) => ({ lng: point.lng(), lat: point.lat() }));

/**
 * A function that computes the barycenter of a list of coordinates
 * @param coordinates - an array of tuples of coordinates
 * @return the barycenter tuple, [latitude, longitude]
 */
export function barycenter(coordinates: Array<Position>) {
	if (coordinates.length === 0) {
		return undefined;
	}

	let bary_lng = 0;
	let bary_lat = 0;
	coordinates.forEach((coordinate) => {
		bary_lng += coordinate.lng;
		bary_lat += coordinate.lat;
	});

	return [bary_lat / coordinates.length, bary_lng / coordinates.length];
}

/**
 * A function that check if the given (lat,lon) point is in the given polygon
 * @param lat latitude of the point
 * @param lon longitude of the point
 * @param polygon polygon to check
 * @returns true if the point is in the polygon, false otherwise
 */
export function inPolygon(lat: number, lon: number, polygon: number[][]) {
	let inside = false;

	for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i, i += 1) {
		const lati = polygon[i][1];
		const loni = polygon[i][0];
		const latj = polygon[j][1];
		const lonj = polygon[j][0];

		const intersect =
			loni > lon !== lonj > lon &&
			lat < ((latj - lati) * (lon - loni)) / (lonj - loni) + lati;
		if (intersect) inside = !inside;
	}

	return inside;
}

/**
 * A function that computes an area of a given polygon
 * @param polygon
 * @returns the area of the polygon
 */
export function areaPolygon(polygon: number[][]) {
	let area = 0;
	let j = polygon.length - 1;

	for (let i = 0; i < polygon.length; i += 1) {
		area += (polygon[0][j] + polygon[0][i]) * (polygon[1][j] - polygon[1][i]);
		j = i;
	}

	return Math.abs(area / 2);
}
