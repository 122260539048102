/**
 * ES Search does not provide all the types needed.
 * Therefore, we define here some types that are usefull to describe an index.
 *
 * The types are deduced from ES documentation.
 * Feel free to remove this file when ES releases the types.
 */

import { z } from "zod";
import { WordMatch } from "./mongoClients";
import { MatchReco, SearchReco } from "./recommendation";

/**
 * Main cruncho destination languages supported by Elastic Search
 */
export const esCrunchoLanguages = [
	"danish",
	"dutch",
	"english",
	"finnish",
	"french",
	"german",
	"spanish",
	"swedish",
] as const;

// Stopwords - https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-stop-tokenfilter.html#analysis-stop-tokenfilter-stop-words-by-lang
const crunchoLanguagesStopWords = [
	z.literal("_danish_"),
	z.literal("_dutch_"),
	z.literal("_english_"),
	z.literal("_finnish_"),
	z.literal("_french_"),
	z.literal("_german_"),
	z.literal("_spanish_"),
	z.literal("_swedish_"),
] as const;
const CrunchoLanguagesStopWordsNameSchema = z.union(crunchoLanguagesStopWords);

// Filters
const esDefaultFilters = [
	z.literal("lowercase"),
	z.literal("asciifolding"),
	// There are way to much default filters, cf https://www.elastic.co/guide/en/elasticsearch/reference/current/analysis-tokenfilters.html
	// add them if you need them, and recreate the types
] as const;
const crunchoLanguagesFilters = [
	z.literal("danish_stop_filter"),
	z.literal("dutch_stop_filter"),
	z.literal("english_stop_filter"),
	z.literal("finnish_stop_filter"),
	z.literal("french_stop_filter"),
	z.literal("german_stop_filter"),
	z.literal("spanish_stop_filter"),
	z.literal("swedish_stop_filter"),
] as const;
const crunchoCustomFilters = [z.literal("example_stop_filter")] as const;

const crunchoLanguagesFilterNameSchema = z.union([
	...esDefaultFilters,
	...crunchoLanguagesFilters,
	...crunchoCustomFilters,
]);
export type ESCrunchoLanguagesFilterName = z.infer<
	typeof crunchoLanguagesFilterNameSchema
>;

const crunchoLanguagesFilterSchema = z.object({
	type: z.literal("stop"),
	stopwords: CrunchoLanguagesStopWordsNameSchema,
});
type ESCrunchoLanguagesFilter = z.infer<typeof crunchoLanguagesFilterSchema>;

export type ESCrunchoLanguagesFilters = Record<
	ESCrunchoLanguagesFilterName,
	ESCrunchoLanguagesFilter
>;

// Analyzers
const crunchoLanguagesNativeAnalyzers = [
	z.literal("danish_analyzer"),
	z.literal("dutch_analyzer"),
	z.literal("english_analyzer"),
	z.literal("finnish_analyzer"),
	z.literal("french_analyzer"),
	z.literal("german_analyzer"),
	z.literal("spanish_analyzer"),
	z.literal("swedish_analyzer"),
] as const;
const crunchoCustomAnalyzers = [
	z.literal("belgium_analyzer"),
	z.literal("example_analyzer"),
] as const;

export const crunchoLanguageAnalyzers = [
	...crunchoLanguagesNativeAnalyzers,
	...crunchoCustomAnalyzers,
] as const;

// We export the next one also because it is used in ./destination.ts
export const crunchoLanguagesAnalyzerNameSchema = z.union(
	crunchoLanguageAnalyzers,
);
export type ESCrunchoLanguagesAnalyzerName = z.infer<
	typeof crunchoLanguagesAnalyzerNameSchema
>;

const crunchoLanguagesAnalyzerSchema = z.object({
	type: z.literal("custom"),
	tokenizer: z.literal("standard"),
	filter: crunchoLanguagesFilterNameSchema.array().optional(),
});
export type ESCrunchoLanguagesAnalyzer = z.infer<
	typeof crunchoLanguagesAnalyzerSchema
>;
export type ESCrunchoLanguagesAnalyzers = Record<
	ESCrunchoLanguagesAnalyzerName,
	ESCrunchoLanguagesAnalyzer
>;

// Index
/**
 * This index is completely incomplete, for this kind of objects yet,
 * so we define something that just meet our needs
 *
 * T is either SearchReco, MatchReco or WordMatch
 * E is the type of the mapping properties from ES (not installed here)
 */
export type ESCrunchoIndex<T extends SearchReco | MatchReco | WordMatch, E> = {
	indexName: string;
	mappings: {
		properties: Record<keyof T, E>;
	};
	settings?: {
		analysis?: {
			analyzer: {
				default: {
					type: "custom";
					tokenizer: string;
					filter: Array<string>;
					// There are other options but we don't use them and I already lost enough time
				};
			} & ESCrunchoLanguagesAnalyzers;
			filter?: ESCrunchoLanguagesFilters;
		};
		number_of_shards?: number;
	};
};
