import { z } from "zod";

const baseConfigSchema = z.object({
	enabled: z.boolean().default(false),
});

const ticketMasterSchema = z.object({
	autoApprove: z.boolean().optional(),
	affiliateId: z.string().optional(),
	city: z.string().array(),
	countryCode: z.string(),
	impactLink: z.string().optional(),
	lat: z.number().optional(),
	locale: z.string().optional(),
	lon: z.number().optional(),
	postalCode: z.union([z.string(), z.array(z.string())]).optional(),
	radius: z.number().optional(), // km
	shareId: z.string().optional(),
	stateCode: z.string().optional(),
}); // could be filled in the future with extra attributes,
export const ticketMasterConfigSchema =
	baseConfigSchema.merge(ticketMasterSchema);
export type TicketMasterConfig = z.infer<typeof ticketMasterConfigSchema>;

const datatourismeSchema = z.object({
	datafeed: z.string(),
	apikey: z.string(),
}); // could be filled in the future with extra attributes,
export const datatourismeConfigSchema =
	baseConfigSchema.merge(datatourismeSchema);
export type DatatourismeConfig = z.infer<typeof datatourismeConfigSchema>;

const forkSchema = z.object({
	forkTrackingCode: z.string(),
}); // could be filled in the future with extra attributes,
export const forkConfigSchema = baseConfigSchema.merge(forkSchema);
export type ForkConfig = z.infer<typeof forkConfigSchema>;

const viatorSchema = z.object({
	partnerId: z.string().min(1).optional(),
}); // could be filled in the future with extra attributes,
export const viatorConfigSchema = baseConfigSchema.merge(viatorSchema);
export type ViatorConfig = z.infer<typeof viatorConfigSchema>;

const tripAdvisorSchema = z.object({
	partnerId: z.string(),
	cityId: z.string().optional(),
	countryId: z.string().optional(),
}); // could be filled in the future with extra attributes,
export const tripAdvisorConfigSchema =
	baseConfigSchema.merge(tripAdvisorSchema);
export type TripAdvisorConfig = z.infer<typeof tripAdvisorConfigSchema>;

const reservixSchema = z.object({
	autoApprove: z.boolean().optional(),
	city: z.union([z.string(), z.array(z.string())]),
}); // could be filled in the future with extra attributes,
export const reservixConfigSchema = baseConfigSchema.merge(reservixSchema);
export type ReservixConfig = z.infer<typeof reservixConfigSchema>;

const songkickSchema = z.object({ autoApprove: z.boolean().optional() }); // could be filled in the future with extra attributes,
export const songkickConfigSchema = baseConfigSchema.merge(songkickSchema);
export type SongkickConfig = z.infer<typeof songkickConfigSchema>;

const foursquareSchema = z.object({}); // could be filled in the future with extra attributes,
const foursquareConfigSchema = baseConfigSchema.merge(foursquareSchema);
export type FoursquareConfig = z.infer<typeof foursquareConfigSchema>;

const facebookEventSchema = z.object({
	autoApprove: z.boolean().optional(),
	city: z.array(z.string()),
	searchTexts: z.array(z.string()),
}); // could be filled in the future with extra attributes,
export const facebookEventConfigSchema =
	baseConfigSchema.merge(facebookEventSchema);
export type FacebookEventConfig = z.infer<typeof facebookEventConfigSchema>;

const eventimSchema = z.object({
	autoApprove: z.boolean().optional(),
	city: z.union([z.string(), z.array(z.string())]),
}); // could be filled in the future with extra attributes,
export const eventimConfigSchema = baseConfigSchema.merge(eventimSchema);
export type EventimConfig = z.infer<typeof eventimConfigSchema>;

const ticketcoSchema = z.object({ autoApprove: z.boolean().optional() }); // could be filled in the future with extra attributes,
export const ticketcoConfigSchema = baseConfigSchema.merge(ticketcoSchema);
export type TicketcoConfig = z.infer<typeof ticketcoConfigSchema>;

const kaxigConfig = z.object({
	autoApprove: z.boolean().optional(),
});
export const kaxigConfigSchema = baseConfigSchema.merge(kaxigConfig);
export type KaxigConfig = z.infer<typeof kaxigConfigSchema>;
