import { z } from "zod";
import { appLanguageCodeSchema } from "../languages";

// ################### //
// Quiz question types //
// ################### //

export const QUIZ_QUESTION_TYPES = {
	YES_NO: "YES/NO",
	MULITPLE_CHOICES: "MCQ",
	OPEN_ANSWER: "OPEN ANSWER",
} as const;
export type QuizQuestionTypesKeys = keyof typeof QUIZ_QUESTION_TYPES;
export type AvailableQuizQuestionTypes =
	typeof QUIZ_QUESTION_TYPES[QuizQuestionTypesKeys];

// ############## //
// Quiz questions //
// ############## //

export const quizQuestionOptionSchema = z.object({
	text: z.string().nonempty(),
	language: appLanguageCodeSchema,
	correct: z.boolean(),
});
export type QuizQuestionOption = z.infer<typeof quizQuestionOptionSchema>;

/**
 * Represents what's inside the database for questions.
 */
export const baseQuizQuestionSchema = z.object({
	_id: z.string(),
	type: z.nativeEnum(QUIZ_QUESTION_TYPES),
	text: z.string().nonempty(),
	language: appLanguageCodeSchema,
	// > 0
	time: z.number().positive().optional(),
	// >= 0
	score: z.number().nonnegative().optional(),
	options: quizQuestionOptionSchema.array().optional(),
});
export type BaseQuizQuestion = z.infer<typeof baseQuizQuestionSchema>;

// #################### //
// Yes/No quiz question //
// #################### //

export const yesNoQuizQuestionSchema = baseQuizQuestionSchema.extend({
	type: z.literal(QUIZ_QUESTION_TYPES.YES_NO),
	options: z.tuple([quizQuestionOptionSchema, quizQuestionOptionSchema]),
});
export type YesNoQuizQuestion = z.infer<typeof yesNoQuizQuestionSchema>;

// ############################## //
// Multiple choices quiz question //
// ############################## //

export const multipleChoicesQuizQuestionSchema = baseQuizQuestionSchema.extend({
	type: z.literal(QUIZ_QUESTION_TYPES.MULITPLE_CHOICES),
	options: z
		.array(quizQuestionOptionSchema)
		.refine(
			(options) => options.some((option) => option.correct),
			"At least one answer should be correct",
		),
});
export type MultipleChoicesQuizQuestion = z.infer<
	typeof multipleChoicesQuizQuestionSchema
>;

// ######################### //
// Open answer quiz question //
// ######################### //

export const openAnswerQuizQuestionSchema = baseQuizQuestionSchema.extend({
	type: z.literal(QUIZ_QUESTION_TYPES.OPEN_ANSWER),
});
export type OpenAnswerQuizQuestion = z.infer<
	typeof openAnswerQuizQuestionSchema
>;

// #################################### //
// Union of all possible quiz questions //
// #################################### //

export const quizQuestionSchema = z.discriminatedUnion("type", [
	yesNoQuizQuestionSchema,
	multipleChoicesQuizQuestionSchema,
	openAnswerQuizQuestionSchema,
]);
export type QuizQuestion = z.infer<typeof quizQuestionSchema>;

// ################################# //
// Question creation or modification //
// ################################# //

// We cannot .omit on a discriminated union, so we have to do it on each
// individual schema.
export const yesNoQuizQuestionCreateInputSchema = yesNoQuizQuestionSchema.omit({
	_id: true,
});
export type YesNoQuizQuestionCreateInput = z.infer<
	typeof yesNoQuizQuestionCreateInputSchema
>;

export const multipleChoicesQuizQuestionCreateInputSchema =
	multipleChoicesQuizQuestionSchema.omit({
		_id: true,
	});
export type MultipleChoicesQuizQuestionCreateInput = z.infer<
	typeof multipleChoicesQuizQuestionCreateInputSchema
>;

export const openAnswerQuizQuestionCreateInputSchema =
	openAnswerQuizQuestionSchema.omit({
		_id: true,
	});
export type OpenAnswerQuizQuestionCreateInput = z.infer<
	typeof openAnswerQuizQuestionCreateInputSchema
>;

export const quizQuestionCreateInputSchema = z.discriminatedUnion("type", [
	yesNoQuizQuestionCreateInputSchema,
	multipleChoicesQuizQuestionCreateInputSchema,
	openAnswerQuizQuestionCreateInputSchema,
]);
export type QuizQuestionCreateInput = z.infer<
	typeof quizQuestionCreateInputSchema
>;

export const yesNoQuizQuestionUpdateInputSchem = yesNoQuizQuestionSchema.extend(
	{
		_id: yesNoQuizQuestionSchema.shape._id.optional(),
	},
);
export type YesNoQuizQuestionUpdateInput = z.infer<
	typeof yesNoQuizQuestionUpdateInputSchem
>;

export const multipleChoicesQuizQuestionUpdateInputSchema =
	multipleChoicesQuizQuestionSchema.extend({
		_id: multipleChoicesQuizQuestionSchema.shape._id.optional(),
	});
export type MultipleChoicesQuizQuestionUpdateInput = z.infer<
	typeof multipleChoicesQuizQuestionUpdateInputSchema
>;

export const openAnswerQuizQuestionUpdateInputSchema =
	openAnswerQuizQuestionSchema.extend({
		_id: openAnswerQuizQuestionSchema.shape._id.optional(),
	});
export type OpenAnswerQuizQuestionUpdateInput = z.infer<
	typeof openAnswerQuizQuestionUpdateInputSchema
>;

export const quizQuestionUpdateInputSchema = z.discriminatedUnion("type", [
	yesNoQuizQuestionUpdateInputSchem,
	multipleChoicesQuizQuestionUpdateInputSchema,
	openAnswerQuizQuestionUpdateInputSchema,
]);

// ############### //
// Type safeguards //
// ############### //

export function isYesNoQuizQuestion(
	question: BaseQuizQuestion,
): question is YesNoQuizQuestion {
	return question.type === QUIZ_QUESTION_TYPES.YES_NO;
}

export function isMultipleChoicesQuizQuestion(
	question: BaseQuizQuestion,
): question is MultipleChoicesQuizQuestion {
	return question.type === QUIZ_QUESTION_TYPES.MULITPLE_CHOICES;
}

export function isOpenAnswerQuizQuestion(
	question: BaseQuizQuestion,
): question is OpenAnswerQuizQuestion {
	return question.type === QUIZ_QUESTION_TYPES.OPEN_ANSWER;
}
