import { StructuredReco } from "@cruncho/cruncho-shared-types";
import i18n from "i18next";
import { enqueueNotification } from "reducers/notificationsReducer";
import {
	receiveSaveConfirmation,
	saveChanges,
} from "reducers/saveRecommendationReducer";
import {
	receiveHash,
	receiveModifiedReco,
	receiveOriginalReco,
	recoLoaded,
	recoUpdate,
	requestHash,
	requestModifiedReco,
	requestOriginalReco,
	selectReco,
} from "reducers/selectedRecoReducer";
import { cleanRecoCachedApi, initProperties } from ".";
import { adminApiPath, apiTsInstance, apiTsRoot } from "../common";
import { AppState, AppThunk } from "../configureStore";
import { getUnsavedModifiedReco } from "../selectors";
import { sendMessage } from "../utils/messageService";
import * as notification from "./notificationActions";

/**
 * ACTION CREATORS
 */

export function fetchOriginalAndModified(id: number): AppThunk {
	// Fetched in sequence to avoid race-conditions
	return async (dispatch) => {
		dispatch(selectReco(id));
		dispatch(requestOriginalReco());
		let res;
		let hashedId;

		dispatch(requestHash());
		try {
			hashedId = await apiTsInstance.get(`${adminApiPath}/encode/${id}`, {
				responseType: "text", // Otherwise axios interprets the string 5e7233 as Infinity
			});
			dispatch(receiveHash(hashedId.data));

			res = await apiTsInstance.get<StructuredReco>(
				`${apiTsRoot}recommendations/${hashedId.data}?language=${
					i18n.language ?? "en-GB"
				}`,
			);
			dispatch(receiveOriginalReco({ data: res.data }));
		} catch (e) {
			dispatch(receiveHash({ data: "", error: !!e }));
			dispatch(notification.errorFetchReco(e));
			dispatch(receiveOriginalReco({ error: !!e }));
			return;
		}

		dispatch(requestModifiedReco());
		try {
			const { data } = await apiTsInstance.get<StructuredReco>(
				`${adminApiPath}/managed-recommendation/${id}`,
			);
			dispatch(receiveModifiedReco({ data }));
			dispatch(initProperties(data));
		} catch (e) {
			dispatch(notification.errorFetchReco(e));
			dispatch(receiveModifiedReco({ error: !!e }));

			return;
		}
		dispatch(notification.successFetchReco());
		dispatch(recoLoaded());
	};
}

export function propertyUpdate(
	property: keyof StructuredReco,
	value: StructuredReco[keyof StructuredReco],
): AppThunk {
	return async (dispatch, getState) => {
		const reco = getState().selectedReco.unsavedModifiedReco;
		dispatch(
			recoUpdate(
				reco
					? {
							...reco,
							[property]: value,
						}
					: reco,
			),
		);
	};
}

export function propertyResetToOriginal(property: keyof StructuredReco) {
	return (dispatch: any, getState: () => AppState) => {
		const originalReco = getState().selectedReco.originalReco.reco;
		if (originalReco) {
			const value = originalReco[property];
			return dispatch(propertyUpdate(property, value));
		}
	};
}

export function propertyResetToSaved(property: keyof StructuredReco) {
	return (dispatch: any, getState: () => AppState) => {
		const savedReco = getState().selectedReco.savedModifiedReco.reco;
		if (savedReco) {
			const value = savedReco[property];
			return dispatch(propertyUpdate(property, value));
		}
	};
}

export function recoResetToSaved(): AppThunk {
	return async (dispatch, getState) => {
		dispatch(recoUpdate(getState().selectedReco.savedModifiedReco.reco));
	};
}

export function recoResetToOriginal(): AppThunk {
	return async (dispatch, getState) => {
		dispatch(recoUpdate(getState().selectedReco.originalReco.reco));
	};
}

export function requestOpeningHoursUpdate(): AppThunk {
	return async (dispatch, getState) => {
		const id = getState().selectedReco.id;
		return apiTsInstance
			.put(`${adminApiPath}/request-opening-hours-update/${id}`, { id })
			.then(() => {
				dispatch(receiveSaveConfirmation({}));
			})
			.then(() => {
				dispatch(
					enqueueNotification({
						details: "",
						header: "Update complete",
						variant: "success",
						persist: false,
						message:
							"The opening hours have been updated with the Google API, if the place is still available on Google",
					}),
				);
			});
	};
}

export function requestOpeningHoursUpdateByDestination(
	destinationSlug: string,
): AppThunk {
	return async (dispatch) =>
		apiTsInstance
			.put(
				`${adminApiPath}/request-opening-hours-update-by-destination/${destinationSlug}`,
				{
					destinationSlug,
				},
			)
			.then(() => {
				dispatch(receiveSaveConfirmation({}));
			})
			.then(() => {
				dispatch(
					enqueueNotification({
						details: "",
						header: "Update complete",
						variant: "success",
						persist: false,
						message: `The opening hours for ${destinationSlug} have been updated with the Google API, if the place is still available on Google`,
					}),
				);
			});
}

export function putChanges(): AppThunk {
	return async (dispatch, getState) => {
		dispatch(saveChanges());
		const reco = getUnsavedModifiedReco(getState());
		const id = getState().selectedReco.id;
		const hash = getState().selectedReco.hash.hash;
		const route = Object.keys(getState().selectedReco.diffSavedUnsaved).includes(
			"fr",
		)
			? `${adminApiPath}/managed-recommendation-translated-fr/${id}`
			: `${adminApiPath}/managed-recommendation/${id}`;
		return apiTsInstance
			.put(route, reco)
			.then(() => {
				dispatch(receiveSaveConfirmation({}));
				cleanRecoCachedApi(id);
			})
			.then(() => {
				try {
					sendMessage(hash);
				} catch (err) {
					dispatch(
						enqueueNotification({
							details: "",
							header: "Your changes are not immediately visible on Cruncho",
							variant: "info",
							persist: false,
							message:
								"Please refresh the Cruncho recommendation page in order to see your updates there",
						}),
					);
				}

				dispatch(fetchOriginalAndModified(id));
				dispatch(notification.successSaveChanges());
			}) // check that flow stops here if error
			.catch((err: any) => {
				dispatch(notification.errorSaveReco(err));
				dispatch(receiveSaveConfirmation(err));
			});
	};
}

export function sendNotificationDoNotDeleteDisplayedReview(): AppThunk {
	return async (dispatch) => {
		dispatch(notification.doNotDeleteDisplayedReview());
	};
}
