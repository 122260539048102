import { z } from "zod";

/**
 * Utility to omit the `_id` from a type.
 */
export type CreateInput<T> = Omit<T, "_id">;

/**
 * Force string to be dates
 */
export const dateSchema = z.preprocess((arg) => {
	if (typeof arg === "number" || typeof arg === "string" || arg instanceof Date)
		return new Date(arg);
	return undefined;
}, z.date());
