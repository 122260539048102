import {
	AppLanguage,
	CountryCode,
	L1,
	AppLanguageCode,
	CountryCodeISO,
	AvailableApisEnum,
} from "@cruncho/cruncho-shared-types";

export const VISIT_KEY = "visit";
export const EAT_KEY = "restaurants";
export const EVENTS_KEY = "events";
export const STAY_KEY = "hotels";

export const L1_TO_CATEGORY: Record<L1, string> = {
	[VISIT_KEY]: "visit",
	[EAT_KEY]: "eat",
	[STAY_KEY]: "stay",
	[EVENTS_KEY]: "events",
};

export const CC_TO_LOCALE_MAP: Record<CountryCode, AppLanguage> = {
	AU: "en-AU",
	BE: "nl-BE",
	CA: "fr-FR",
	CN: "zh-Hans-CN",
	DE: "de-DE",
	DK: "da-DK",
	ES: "es-ES",
	FR: "fr-FR",
	GB: "en-GB",
	IT: "it-IT",
	NL: "nl-NL",
	NO: "nb-NO",
	PT: "pt-PT",
	RO: "ro-RO",
	RU: "ru-RU",
	SE: "sv-SE",
	TW: "zh-Hant-TW",
	US: "en-US",
};

export const CC_TO_APPLANGUAGECODE_MAP: Record<CountryCode, AppLanguageCode> = {
	AU: "en-AU",
	BE: "nl",
	CA: "fr",
	CN: "zh_Hans",
	DE: "de",
	DK: "da",
	ES: "es",
	FR: "fr",
	GB: "en",
	IT: "it",
	NL: "nl",
	NO: "nb",
	PT: "pt",
	RO: "ro",
	RU: "ru",
	SE: "sv",
	TW: "zh_Hant",
	US: "en-US",
};

export const APPLANGUAGECODE_TO_CC_MAP: Record<AppLanguageCode, CountryCode> = {
	da: "DK",
	de: "DE",
	en: "GB",
	"en-AU": "AU",
	"en-US": "US",
	es: "ES",
	fr: "FR",
	it: "IT",
	nb: "NO",
	nl: "NL",
	pt: "PT",
	ro: "RO",
	ru: "RU",
	sv: "SE",
	zh_Hans: "CN",
	zh_Hant: "TW",
};

/**
 * This list is extracted from the english wikipedia: https://en.wikipedia.org/wiki/ISO_3166-1#Current_codes
 *
 * PLEASE DO NOT SORT THIS LIST
 *
 * Some countries where listed as <Country Name short> (Longer Name), and where duplicated to cover as most
 * situations as possible. This duplication breaks the alphabetical order, so it is important not to sort
 * to keep related lines together.
 *
 * Example:
 * - "Moldova, Republic of": "MD"
 * became:
 * - "Moldova: "MD",
 * - "Moldova, Republic of": "MD",
 * - "Republic of Moldova": "MD",
 *
 */
export const countryNamesToISOCodes: Record<string, CountryCodeISO> = {
	"Islamic Republic of Afghanistan Afghanistan[b]": "AF",
	Afghanistan: "AF",
	"Åland Islands": "AX",
	Albania: "AL",
	Algeria: "DZ",
	"American Samoa": "AS",
	Andorra: "AD",
	Angola: "AO",
	Anguilla: "AI",
	Antarctica: "AQ",
	"Antigua and Barbuda": "AG",
	Barbuda: "AG",
	Antigua: "AG",
	Argentina: "AR",
	Armenia: "AM",
	Aruba: "AW",
	Australia: "AU",
	Austria: "AT",
	Azerbaijan: "AZ",
	Bahamas: "BS",
	Bahrain: "BH",
	Bangladesh: "BD",
	Barbados: "BB",
	Belarus: "BY",
	Belgium: "BE",
	Belize: "BZ",
	Benin: "BJ",
	Bermuda: "BM",
	Bhutan: "BT",
	Bolivia: "BO",
	"Plurinational State of Bolivia": "BO",
	Bonaire: "BQ",
	"Bosnia and Herzegovina": "BA",
	Bosnia: "BA",
	Herzegovina: "BA",
	Botswana: "BW",
	"Bouvet Island": "BV",
	Brazil: "BR",
	"British Indian Ocean Territory": "IO",
	"Brunei Darussalam": "BN",
	Bulgaria: "BG",
	"Burkina Faso": "BF",
	Burundi: "BI",
	"Cabo Verde": "CV",
	Cambodia: "KH",
	Cameroon: "CM",
	Canada: "CA",
	"Cayman Islands": "KY",
	"Central African Republic": "CF",
	Chad: "TD",
	Chile: "CL",
	"China[b]": "CN",
	"Christmas Island": "CX",
	"Cocos (Keeling) Islands": "CC",
	Colombia: "CO",
	Comoros: "KM",
	Congo: "CG",
	"Democratic Republic of the Congo": "CD",
	"Congo (Democratic Republic of the)": "CD",
	"Cook Islands": "CK",
	"Costa Rica": "CR",
	"Côte d'Ivoire": "CI",
	Croatia: "HR",
	Cuba: "CU",
	Curaçao: "CW",
	"Cyprus[b]": "CY",
	Czechia: "CZ",
	Denmark: "DK",
	Djibouti: "DJ",
	Dominica: "DM",
	"Dominican Republic": "DO",
	Ecuador: "EC",
	Egypt: "EG",
	"El Salvador": "SV",
	"Equatorial Guinea": "GQ",
	Eritrea: "ER",
	Estonia: "EE",
	Eswatini: "SZ",
	Ethiopia: "ET",
	"Falkland Islands": "FK",
	"Faroe Islands": "FO",
	Fiji: "FJ",
	Finland: "FI",
	France: "FR",
	"French Guiana": "GF",
	"French Polynesia": "PF",
	"French Southern Territories": "TF",
	Gabon: "GA",
	Gambia: "GM",
	Georgia: "GE",
	Germany: "DE",
	Ghana: "GH",
	Gibraltar: "GI",
	Greece: "GR",
	Greenland: "GL",
	Grenada: "GD",
	Guadeloupe: "GP",
	Guam: "GU",
	Guatemala: "GT",
	Guernsey: "GG",
	Guinea: "GN",
	"Guinea-Bissau": "GW",
	Guyana: "GY",
	Haiti: "HT",
	"Heard Island and McDonald Islands": "HM",
	"Holy See": "VA",
	Honduras: "HN",
	"Hong Kong": "HK",
	Hungary: "HU",
	Iceland: "IS",
	India: "IN",
	Indonesia: "ID",
	Iran: "IR",
	"Islamic Republic of Iran": "IR",
	Iraq: "IQ",
	Ireland: "IE",
	"Isle of Man": "IM",
	Israel: "IL",
	Italy: "IT",
	Jamaica: "JM",
	Japan: "JP",
	Jersey: "JE",
	Jordan: "JO",
	Kazakhstan: "KZ",
	Kenya: "KE",
	Kiribati: "KI",
	"Korea (Democratic People's Republic of)": "KP",
	"North Korea": "KP",
	"Democratic People's Republic of Korea": "KP",
	"South Korea": "KR",
	"Republic of Korea": "KR",
	"Korea, Republic of": "KR",
	Kuwait: "KW",
	Kyrgyzstan: "KG",
	"Lao People's Democratic Republic": "LA",
	Laos: "LA",
	Latvia: "LV",
	Lebanon: "LB",
	Lesotho: "LS",
	Liberia: "LR",
	Libya: "LY",
	Liechtenstein: "LI",
	Lithuania: "LT",
	Luxembourg: "LU",
	Macao: "MO",
	Madagascar: "MG",
	Malawi: "MW",
	Malaysia: "MY",
	Maldives: "MV",
	Mali: "ML",
	Malta: "MT",
	"Marshall Islands": "MH",
	Martinique: "MQ",
	Mauritania: "MR",
	Mauritius: "MU",
	Mayotte: "YT",
	Mexico: "MX",
	"Micronesia (Federated States of)": "FM",
	"Federated States of Micronesia": "FM",
	Micronesia: "FM",
	Moldova: "MD",
	"Moldova, Republic of": "MD",
	"Republic of Moldova": "MD",
	Monaco: "MC",
	Mongolia: "MN",
	Montenegro: "ME",
	Montserrat: "MS",
	Morocco: "MA",
	Mozambique: "MZ",
	Myanmar: "MM",
	Namibia: "NA",
	Nauru: "NR",
	Nepal: "NP",
	Netherlands: "NL",
	"New Caledonia": "NC",
	"New Zealand": "NZ",
	Nicaragua: "NI",
	Niger: "NE",
	Nigeria: "NG",
	Niue: "NU",
	"Norfolk Island": "NF",
	"North Macedonia": "MK",
	"Northern Mariana Islands": "MP",
	Norway: "NO",
	Oman: "OM",
	Pakistan: "PK",
	Palau: "PW",
	Palestine: "PS",
	"Palestine, State of": "PS",
	"State of Palestine": "PS",
	Panama: "PA",
	"Papua New Guinea": "PG",
	Paraguay: "PY",
	Peru: "PE",
	Philippines: "PH",
	Pitcairn: "PN",
	Poland: "PL",
	Portugal: "PT",
	"Puerto Rico": "PR",
	Qatar: "QA",
	Réunion: "RE",
	Romania: "RO",
	Russia: "RU",
	"Russian Federation": "RU",
	Rwanda: "RW",
	"Saint Barthélemy": "BL",
	"Saint Helena": "SH",
	"Saint Kitts and Nevis": "KN",
	"Saint Lucia": "LC",
	"Saint Martin (French part)": "MF",
	"Saint Pierre and Miquelon": "PM",
	"Saint Vincent and the Grenadines": "VC",
	Samoa: "WS",
	"San Marino": "SM",
	"Sao Tome and Principe": "ST",
	"Saudi Arabia": "SA",
	Senegal: "SN",
	Serbia: "RS",
	Seychelles: "SC",
	"Sierra Leone": "SL",
	Singapore: "SG",
	"Sint Maarten (Dutch part)": "SX",
	Slovakia: "SK",
	Slovenia: "SI",
	"Solomon Islands": "SB",
	Somalia: "SO",
	"South Africa": "ZA",
	"South Georgia and the South Sandwich Islands": "GS",
	"South Sudan": "SS",
	Spain: "ES",
	"Sri Lanka": "LK",
	Sudan: "SD",
	Suriname: "SR",
	"Svalbard and Jan Mayen[e]": "SJ",
	Sweden: "SE",
	Switzerland: "CH",
	"Syrian Arab Republic": "SY",
	Taiwan: "TW",
	"Taiwan, Province of China[b]": "TW",
	Tajikistan: "TJ",
	Tanzania: "TZ",
	"Tanzania, United Republic of": "TZ",
	"United Republic of Tanzania": "TZ",
	Thailand: "TH",
	"Timor-Leste": "TL",
	Togo: "TG",
	Tokelau: "TK",
	Tonga: "TO",
	"Trinidad and Tobago": "TT",
	Tunisia: "TN",
	Turkey: "TR",
	Turkmenistan: "TM",
	"Turks and Caicos Islands": "TC",
	Tuvalu: "TV",
	Uganda: "UG",
	Ukraine: "UA",
	"United Arab Emirates": "AE",
	"United Kingdom of Great Britain and Northern Ireland": "GB",
	"United Kingdom": "GB",
	"United States of America": "US",
	"United States Minor Outlying Islands[f]": "UM",
	Uruguay: "UY",
	Uzbekistan: "UZ",
	Vanuatu: "VU",
	Venezuela: "VE",
	"Venezuela (Bolivarian Republic of)": "VE",
	"Bolivarian Republic of Venezuela": "VE",
	"Viet Nam": "VN",
	"Virgin Islands (British)": "VG",
	"Virgin Islands (U.S.)": "VI",
	"Wallis and Futuna": "WF",
	"Western Sahara": "EH",
	Yemen: "YE",
	Zambia: "ZM",
	Zimbabwe: "ZW",
};

interface Category1Object {
	label: string;
	l1_categories: string[];
}

type CatMapping = {
	visit: Category1Object;
	restaurants: Category1Object;
	hotels: Category1Object;
	events: Category1Object;
};

export const CATEGORY_MAPPINGS: CatMapping = {
	visit: {
		label: "See & Do Filters",
		l1_categories: ["visit"],
	},
	restaurants: {
		label: "Eat & Drink Filters",
		l1_categories: ["restaurants"],
	},
	hotels: {
		label: "Hotel Filters",
		l1_categories: ["hotels"],
	},
	events: {
		label: "Live Events Filters",
		l1_categories: ["events"],
	},
};

const getPossibleCategoryRoutes = () =>
	["see-and-do", "eat-and-drink", "hotels", "live-events"].join("|");

export const RESULTS_ROUTE_PATH =
	`/:area([a-zA-Z1-9\-]+?|)?/results/:category(${getPossibleCategoryRoutes()})?/:filters?`.replace(
		"//",
		"/",
	);

/**
 * This is the path we use to access the cruncho S3 service.
 * It goes through the API, which redirects with a specific state in the url (check route /cdn)
 */
export const s3DefaultPath = "https://api-ts.cruncho.co/cdn?path=plain/s3://";

/**
 * `https://api-ts.cruncho.co/cdn?path=plain/s3://client-specific-images/client-specific`
 * This is the path to the Amazon S3 bucket containing all client specific images.
 * It is composed of subfolders, with name the city UID (which is supposed to be the same)
 * as the city mongo _id, and sometimes called `destination_slug`
 *
 * Each subfolder is usually composed of
 * - cover.jpg (used in themes.scss + seen in the travellist page - maybe use a specific image ?)
 * - email.jpg (used in mailgun - MUST be present)
 * - logo.png / logo.svg (used in themes.scss)
 * - footer.svg (optional - if city has field city.features.partnerFooter)
 *
 * Some cities have black themes so the logo is sometimes named `logo-on-white` or something...
 */
export const s3ClientImagesPath = `${s3DefaultPath}client-specific-images/client-specific`;

export const NB_UNSPLASH_PICTURES_PER_PAGE = 20;

/**
 * Maximum resolution supported by the image proxy for security reasons.
 *
 * [Documentation](https://docs.imgproxy.net/configuration?id=security)
 */
export const MAX_IMAGE_RESOLUTION = 16800000;

/**
 * APIs whose ratings are between 0 and 10.
 */
export const API_RANKING_UP_TO_10 = [
	AvailableApisEnum.foursquare,
	AvailableApisEnum.fork,
];

export const PUBLISHER_NAME_TO_LINK = {
	"provence tourisme": "https://www.myprovence.fr/",
};

/**
 * Cache key prefix when caching the live event retrieved from the Events API.
 */
export const EVENTS_API_LIVE_EVENT_CACHE_KEY_PREFIX =
	"events-api_events-api::event_event";
