import { L1 } from "@cruncho/cruncho-shared-types";
import { AppState } from "../configureStore";

export function getSelectedRecoL1(state: AppState): L1 | "" {
	const selectedReco = state.selectedReco.unsavedModifiedReco;
	if (selectedReco) {
		return selectedReco.l1;
	}
	return "";
}

export const getSectionFromL1 = (l1: L1 | ""): string => {
	switch (l1) {
		case "events":
			return "Live Events";
		case "hotels":
			return "Hotels";
		case "restaurants":
			return "Eat & Drink";
		case "visit":
			return "See & Do";
		default:
			return "";
	}
};
