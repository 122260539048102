import { TranslationFunction } from "@cruncho/cruncho-shared-types";

export const defaultTranslationFunction: TranslationFunction = (
	...args: any[]
) => {
	let x = args[0];
	const y = args[1];

	if (typeof y === "string") return y;
	if (y) {
		Object.entries(y).forEach(([key, value]) => {
			x = x.replace(`{{${key}}}`, value);
		});
	}

	return x;
};
