import { z } from "zod";

export const L3SlugWeightsSchema = z.object({
	id: z.number(),
	name: z.string(),
	slug: z.string(),
	weight: z.number(),
});

export type L3SlugWeights = z.infer<typeof L3SlugWeightsSchema>;

export const L2SlugWeightsSchema = z.object({
	id: z.number(),
	name: z.string(),
	slug: z.string(),
	children: L3SlugWeightsSchema.array(),
	weight: z.number().optional(),
});

export type L2SlugWeights = z.infer<typeof L2SlugWeightsSchema>;

export const L1SlugWeightsSchema = z.object({
	id: z.number(),
	name: z.string(),
	slug: z.string(),
	children: L2SlugWeightsSchema.array(),
});

export type L1SlugWeights = z.infer<typeof L1SlugWeightsSchema>;

export const SlugWeightsSchema = L1SlugWeightsSchema.array();

export type SlugWeights = z.infer<typeof SlugWeightsSchema>;

export const CalibrationSlugsSchema = z.object({
	_id: z.any(),
	slug_weights: SlugWeightsSchema,
	name: z.string(),
});

export type CalibrationSlugs = z.infer<typeof CalibrationSlugsSchema>;
