import { AdminGroup } from "@cruncho/cruncho-shared-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface AdminGroupState {
	async: AsyncCall;
	adminGroup: AdminGroup;
}

const initialState: AdminGroupState = {
	async: initAsync(),
	adminGroup: {
		_id: "",
		superAdmin: false,
		authorizedProperties: [],
		authorizedDestinations: [],
	},
};

const adminGroupSlice = createSlice({
	name: "adminGroup",
	initialState,
	reducers: {
		requestAdminGroup(state) {
			state.async = startAsync();
		},
		receiveAdminGroup(
			state,
			action: PayloadAction<{ data?: AdminGroup; error?: any }>,
		) {
			if (action.payload.error) {
				state.async = handleResponse(action.payload);
				return;
			}

			const { payload } = action;
			if (payload.data) state.adminGroup = payload.data;
			state.async = handleResponse(action.payload);
		},
	},
});

export const { receiveAdminGroup, requestAdminGroup } = adminGroupSlice.actions;

export default adminGroupSlice.reducer;
