import { z } from "zod";
import { currencyCodeSchema } from "../currencies";

// I know I could import it from event/liveEvent, but then it makes zodToJsonSchema
// parsing fail for some reason
const eventDateSchema = z.object({
	startDate: z.date(),
	endDate: z.date(),
});

export enum RSVPAnswerStatusEnum {
	ALREADY_ANSWERED = "ALREADY_ANSWERED",
	MISSING_FIELD = "MISSING_FIELD",
	CANCELLED = "CANCELLED",
	BOOKED = "BOOKED",
	CLEARED = "VALUE DELETED",
}

export enum RSVPAnswerAgeGroups {
	CHILD = "CHILD",
	ADULT = "ADULT",
	CLEARED = "VALUE DELETED",
}

export const RSVPUserSchema = z.object({
	fullName: z.string(),
	ageGroup: z.nativeEnum(RSVPAnswerAgeGroups),
	age: z.number().optional(),
	specialDiet: z.string().optional(),
	priceId: z.string().optional(),
	// record the price detail in here for convenient, so we will be able to directly use it without querying
	price: z.number().min(0).optional(),
	currency: currencyCodeSchema.default("SEK").optional(),
	label: z.string().optional(),
});
export type RSVPUser = z.infer<typeof RSVPUserSchema>;

export const RSVPAnswerSchema = z.object({
	_id: z.string(),
	answeredAt: z.date(),
	cancelToken: z.string(),
	company: z.string().optional(),
	dateSelected: eventDateSchema.optional(),
	eventId: z.string(),
	email: z.string().email(),
	phone: z.string().optional(),
	users: z.array(RSVPUserSchema),
	status: z.nativeEnum(RSVPAnswerStatusEnum),
	outdated: z.boolean().default(false),
});
export type RSVPAnswer = z.infer<typeof RSVPAnswerSchema>;
