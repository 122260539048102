import { z } from "zod";
import { countryCodeISOSchema } from "./countries";
import { autocompleteProviderSchema } from "./destination";
import { appLanguageCodeSchema } from "./languages";

export const reverseGeocodingParamsSchema = z.object({
	address: z.string(),
	country: countryCodeISOSchema.optional(),
	language: appLanguageCodeSchema,
	latitude: z.number().optional(),
	longitude: z.number().optional(),
	placeId: z.string().optional(),
	provider: autocompleteProviderSchema,
});
export type ReverseGeocodingParams = z.infer<
	typeof reverseGeocodingParamsSchema
>;

export interface GeoCoderResult {
	/** Full address coming from the result */
	address: string;
	/** City name */
	city: string;
	/** Country name */
	country: string;
	coordinates: {
		lat: number;
		lng: number;
	};
	/** placeId */
	placeId: string;
	postalCode?: string;
	region?: string;
	/** Return the first part of the address inferred as a venue name */
	venueName?: string;
	/** Zoom to be shown in the map */
	zoom: number;
}
