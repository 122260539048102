import { z } from "zod";

export const EMLevelPermissionEnum = {
	ORGANIZER: "ORGANIZER",
	MODERATOR: "MODERATOR",
	ADMIN: "ADMIN",
	TRUSTED: "TRUSTED ORGANIZER",
} as const;

export type EMLevelPermission =
	(typeof EMLevelPermissionEnum)[keyof typeof EMLevelPermissionEnum];

export const emUserPermissionSchema = z.record(
	z.nativeEnum(EMLevelPermissionEnum),
);
export type EMUserPermission = z.infer<typeof emUserPermissionSchema>;

export const userSchema = z.object({
	_id: z.string(),
	company: z.string(),
	email: z.string(),
	disabled: z.boolean().optional(),
	groups: z.string().array().optional(),
	lastLogin: z.string(),
	name: z.string(),
	phone: z.string(),
	permissions: emUserPermissionSchema,
	quizModerator: z.record(z.boolean()).optional(),
	surname: z.string(),
	// For Kungsbacka federated login, they provide KungsbackaId as the unique identifier, in the form of ienkr5s7yunzxj9s@kungsbacka.se
	// We map KungsbackaId to cognito email, and created an attribute custom:realemail_kungsbacka on cognito to host the real email
	// kungsbackaId can be used to search the user on Cognito
	kungsbackaId: z.string().optional(),
});

export type User = z.infer<typeof userSchema>;

export const groupSchema = z.object({
	_id: z.string(),
	destinationSlug: z.string(),
	name: z.string(),
	members: z.string().array(),
});

export type Group = z.infer<typeof groupSchema>;
