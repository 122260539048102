import moment from "moment";
import { PartialBy } from "..";
import { AppLanguageCode, appLanguageCodeSchema } from "../languages";
import { AvailableApisEnum, Review } from "./properties";
import {
	BaseReco,
	MatchReco,
	SearchReco,
	StructuredReco,
	UnhashedBaseReco,
	UnhashedStructuredReco,
} from "./recommendation";

/**
 * Convert a structured reco to a base reco
 * @param reco The base reco to convert
 * @returns The converted structured reco
 */
export function convertRecommendationFromBaseToStructured(
	reco: PartialBy<BaseReco, "id">,
): PartialBy<StructuredReco, "id">;
export function convertRecommendationFromBaseToStructured(
	reco: PartialBy<UnhashedBaseReco, "id">,
): PartialBy<UnhashedStructuredReco, "id">;
export function convertRecommendationFromBaseToStructured(
	reco: PartialBy<BaseReco | UnhashedBaseReco, "id">,
): PartialBy<StructuredReco | UnhashedStructuredReco, "id"> {
	return {
		...reco,
		da: {},
		de: {},
		en: {},
		"en-AU": {},
		"en-US": {},
		es: {},
		fr: {},
		it: {},
		nb: {},
		nl: {},
		pt: {},
		ro: {},
		ru: {},
		sv: {},
		zh_Hans: {},
		zh_Hant: {},
	};
}

export function convertRecommendationFromStructuredToMatch(
	structuredReco: UnhashedStructuredReco,
): MatchReco {
	return {
		address: structuredReco.address,
		destinationSlug: structuredReco.destinationSlug,
		eventStart: structuredReco.eventStart,
		eventEnd: structuredReco.eventEnd,
		geometry: {
			lat: structuredReco.geometry.lat,
			lon: structuredReco.geometry.lng,
		},
		id: structuredReco.id,
		l1: structuredReco.l1,
		name: structuredReco.name,
		shouldMatch: structuredReco.shouldMatch,
	};
}

function timeStrToMilitary(time_str: string = "00:00"): number {
	const [hours, minutes] = time_str.split(":").map((t) => parseInt(t, 10));

	return hours * 100 + minutes;
}

function isBookable(api: string): boolean {
	// Had to this ugly thing (api as AvailableApisEnum) because available_apis is everywhere
	return [
		AvailableApisEnum.tripadvisor,
		AvailableApisEnum.fork,
		AvailableApisEnum.ticketmaster,
		AvailableApisEnum.viator,
		AvailableApisEnum.hotelscombined,
	].includes(api as AvailableApisEnum);
}

function reducer(
	acc: Accumulator,
	{ createdAt, rating, api }: Review,
): Accumulator {
	if (createdAt && rating) {
		let correctedRating = rating;
		if (api === "fork") correctedRating /= 2;
		const timeWeight = 100 / moment().add(180, "days").diff(createdAt, "days");
		const weightedRating = correctedRating * timeWeight;
		return {
			weight_sum: acc.weight_sum + timeWeight,
			rating_sum: acc.rating_sum + weightedRating,
		};
	}
	return acc;
}

interface Accumulator {
	weight_sum: number;
	rating_sum: number;
}

export function calculateYearWeight(reviews: Review[]): number {
	const { rating_sum: ratingSum, weight_sum: weightSum } = reviews.reduce(
		reducer,
		{ rating_sum: 0, weight_sum: 0 },
	);

	return weightSum > 0 ? (20 * ratingSum) / weightSum : 0;
}

export function existRecentReview(reviews: Review[]): boolean {
	const recentReview = reviews.find(
		(review) => review.createdAt && moment().diff(review.createdAt, "days") < 365,
	);
	if (recentReview) return true;
	return false;
}

/**
 * Turn a structured reco into a search reco, used in Elastic Search
 * @param structuredReco
 * @returns
 */
export function convertRecommendationFromStructuredToSearch(
	structuredReco: UnhashedStructuredReco,
): SearchReco {
	const translatedNames: { [key in AppLanguageCode]?: string } = {};
	const displayComments: { [key in AppLanguageCode]?: string } = {};

	appLanguageCodeSchema.options.forEach((lang: AppLanguageCode) => {
		if (structuredReco[lang]) {
			translatedNames[lang] = structuredReco[lang].name;
			displayComments[lang] = structuredReco[lang].displayComment;
		}
	});

	return {
		apisCount: structuredReco.availableApis.length,
		availableApis: structuredReco.availableApis,
		address: structuredReco.address,

		bookUrl: structuredReco.bookUrl,
		bookableApisCount: structuredReco.availableApis.filter(isBookable).length,

		categories: structuredReco.categories,
		city: structuredReco.city,
		commentsCount: (structuredReco.reviews ?? []).length,
		creator: structuredReco.creator,
		customBoost: structuredReco.customBoost,

		dateWeightedScore: calculateYearWeight(structuredReco.reviews ?? []),
		description: structuredReco.description,
		destinationSlug: structuredReco.destinationSlug,
		displayComment: structuredReco.displayComment,

		eventEnd: structuredReco.eventEnd,
		existRecentComment: existRecentReview(structuredReco.reviews ?? []),
		eventStart: structuredReco.eventStart,
		eventVenueName: structuredReco.eventVenueName,

		formats: structuredReco.formats,

		geometry: {
			lat: structuredReco.geometry.lat,
			lon: structuredReco.geometry.lng,
		},

		hasPrice: !!structuredReco.price,
		hide: structuredReco.hide ?? false,
		hideReason: structuredReco.hideReason,

		id: structuredReco.id,
		isBookable: !!structuredReco.bookUrl,
		isFree: structuredReco.isFree,
		isHotel: structuredReco.categories.includes("hotel"),
		isPinned: !!structuredReco.isPinned,
		isRestaurant: structuredReco.categories.includes("restaurant"),
		isSponsored: !!structuredReco.isSponsored,

		l1: structuredReco.l1,
		l2: structuredReco.l2,
		label: structuredReco.label,
		lastUpdated: structuredReco.lastUpdated,

		menuUrl: structuredReco.menuUrl,

		name: structuredReco.name,

		isOnline: structuredReco.online,
		openingHours: structuredReco.openingHours?.map((t) => ({
			...t,
			militaryOpen: timeStrToMilitary(t.openTime),
			militaryClose: timeStrToMilitary(t.closeTime),
		})),
		organizer: structuredReco.organizer ?? "",

		phoneNumberAvailable: !!structuredReco.internationalPhoneNumber,
		photos: structuredReco.photos,
		photosCount: structuredReco.photos.length,
		postDate: structuredReco.postDate,
		price: structuredReco.price,

		rankingsCount: structuredReco.reviews.filter(
			(review) => review.api && review.createdAt && review.rating,
		).length,
		rating: structuredReco.rating,
		ratings: (structuredReco.reviews ?? [])
			.filter((review) => review.api && review.createdAt)
			.map<SearchReco["ratings"][number]>((review) => {
				let createdDate: string | undefined;
				try {
					createdDate = review.createdAt
						? new Date(review.createdAt).toISOString()
						: undefined;
				} catch {
					createdDate = undefined;
				}
				return {
					api: review.api,
					createdAt: createdDate,
					rating: review.rating,
				};
			}),
		ratingFork:
			structuredReco.apiRatings?.find((e) => e.api === AvailableApisEnum.fork)
				?.value ?? 0,
		ratingFoursquare:
			structuredReco.apiRatings?.find(
				(e) => e.api === AvailableApisEnum.foursquare,
			)?.value ?? 0,
		ratingGoogle:
			structuredReco.apiRatings?.find((e) => e.api === AvailableApisEnum.google)
				?.value ?? 0,
		ratingViator:
			structuredReco.apiRatings?.find((e) => e.api === AvailableApisEnum.viator)
				?.value ?? 0,
		ratingYelp:
			structuredReco.apiRatings?.find((e) => e.api === AvailableApisEnum.yelp)
				?.value ?? 0,
		reviewsUrl: structuredReco.reviewsUrl,

		tag: structuredReco.tag,
		translatedNames,

		videosCount: (structuredReco.videos ?? []).length,

		websiteAvailable: !!structuredReco.websiteUrl,
	};
}
