import { z } from "zod";

export const sessionOrderSchema = z.object({
	_id: z.string(),
	practitionerId: z.string(),
	sessionIds: z.string().array(),
});
export const sessionOrderNoIdSchema = sessionOrderSchema.omit({ _id: true });

export type SessionOrder = z.infer<typeof sessionOrderSchema>;
export type SessionOrderNoId = Omit<SessionOrder, "_id">;
