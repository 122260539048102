import { z } from "zod";

export interface AuthQueryParams {
	destination: string;
	key: string;
}

export interface SessionMe {
	isloggedin: boolean;
	username?: string;
	email?: string;
	id?: string;
	adminGroup?: AdminGroup | false;
	pictureUrl?: string;
	// encoded userId
	userId?: string;
}

export interface SessionMeSoulcircus {
	isloggedin: boolean;
	username?: string;
	email?: string;
	id?: string;
	adminGroup?: AdminGroup | false;
	pictureUrl?: string;
	// encoded userId
	userId?: string;
	firstname?: string;
	lastname?: string;
}

export const adminGroupSchema = z.object({
	_id: z.string(),
	superAdmin: z.boolean(),
	authorizedDestinations: z.string().array(),
	authorizedProperties: z.string().array(),
});
export type AdminGroup = z.infer<typeof adminGroupSchema>;

export const ALL_AUTHSOURCES = [
	"google",
	"facebook",
	"cognito",
	"apple",
	"laprovence",
	"email",
] as const;
export type AuthSources = (typeof ALL_AUTHSOURCES)[number];

export interface DecodedToken {
	sub: string;
	anonymous: boolean;
	destinationSlug: string;
}

/**
 * The OAuthUserData is the object we handle before saving it to the mongo database.
 * Once saved, it becomes a TripcruncherUser Document.
 */
export interface OAuthUserData {
	/**
	 * The sub is the name of the field extracted from the token. It is renamed as "_id" when saved in the database.
	 */
	sub: string;
	email?: string;
	name?: string;
	/**
	 * When logging in, there is only one source. This will be stored in an array in mongo afterwards
	 */
	source: AuthSources;
	locale?: string;
	pictureUrl?: string;
	ip?: string;
}
