import { AvailableApisEnum } from "@cruncho/cruncho-shared-types";
import { isTicketMasterLink } from "./urls";

export const UNSUPPORTED_PROVIDER = "UNSUPPORTED_PROVIDER";

export function getBookProvider(
	bookUrl?: string,
): AvailableApisEnum | typeof UNSUPPORTED_PROVIDER {
	const isTicketMasterBookLink = bookUrl && isTicketMasterLink(bookUrl);
	const isSongkickBookLink = bookUrl && bookUrl.includes("songkick.com");
	const isReservixBookLink = bookUrl && bookUrl.includes("reservix.de");
	const isEventimBookLink = bookUrl && bookUrl.includes("eventim");
	const isCrunchoBookLink = bookUrl && bookUrl.includes("rsvp.cruncho");
	const isTicketcoBookLink = bookUrl && bookUrl.includes("ticketco.events");

	// Special case to detect TicketMaster links provided through the eventmanager, since one of our clients does that a lot and we can gain money out of it.
	if (isTicketMasterBookLink) return AvailableApisEnum.ticketmaster;

	if (isSongkickBookLink) return AvailableApisEnum.songkick;

	if (isReservixBookLink) return AvailableApisEnum.reservix;

	if (isEventimBookLink) return AvailableApisEnum.eventim;

	if (isCrunchoBookLink) return AvailableApisEnum.cruncho;

	if (isTicketcoBookLink) return AvailableApisEnum.ticketco;

	return UNSUPPORTED_PROVIDER;
}
