import { createSlice } from "@reduxjs/toolkit";

export type authHeaderState = true | false;

const isAuthHeaderSetSlice = createSlice({
	name: "isAuthHeaderSet",
	initialState: false as authHeaderState,
	reducers: {
		setAuthHeader() {
			return true;
		},
	},
});

export const { setAuthHeader } = isAuthHeaderSetSlice.actions;

export default isAuthHeaderSetSlice.reducer;
