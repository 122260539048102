import { AvailableApisEnum, Photo } from "@cruncho/cruncho-shared-types";
import { adminApiPath, apiTsInstance } from "../common";
import { AppState } from "../configureStore";
import { errorAddOwnPhoto } from "./notificationActions";
import { addPhoto } from "./propertyActions";

async function uploadPicture(
	file: File,
	key: string,
	folder: string,
): Promise<string> {
	const formData = new FormData();

	formData.append("key", key);

	formData.append("folder", folder);

	formData.append("file", file);

	const res = await apiTsInstance.post<string>(
		`${adminApiPath}/pictures`,

		formData,

		{
			headers: {
				"Content-Type": "multipart/form-data",
			},
		},
	);

	return res.data;
}

function generateFileName(recoId: number, file: File) {
	const fileType = file.name.split(".").slice(-1)[0];
	return `${recoId}-${Math.random().toString(36).substring(2, 11)}.${fileType}`;
}

async function uploadImage(file: File, recoId: number): Promise<string> {
	const fileName = generateFileName(recoId, file);

	const regex = /(\!|\@|\#|\$|\%|\^|\&|\*|\=|\;|\:|\'|\"|\<|\>|\,|\.|\?)/gi;
	const regexS = /(\(|\)|\[|\])/gi;
	const regexSpace = / /gi;
	const regexPlus = /\+/gi;
	const key = fileName
		.normalize("NFKD")
		.replace(/[\u0300-\u036F]/g, "")
		.replaceAll(regexSpace, "_")
		.replaceAll(regexPlus, "_")
		.replaceAll(regexS, "_")
		.replaceAll(regex, "_");
	const url = await uploadPicture(file, key, recoId.toString());

	return url;
}

export function addOwnPhoto(file: any) {
	return async (dispatch: any, getState: () => AppState) => {
		const recoId = getState().selectedReco.id;
		try {
			const photo: Photo = {
				api: AvailableApisEnum.cruncho,
				url: await uploadImage(file, recoId),
			};
			dispatch(addPhoto(photo));
		} catch (err) {
			dispatch(errorAddOwnPhoto(err));
		}
	};
}

export default addOwnPhoto;
