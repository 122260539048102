import {
	AdminGroup,
	DecodedToken,
	PopulatedDestination,
} from "@cruncho/cruncho-shared-types";
import { AppThunk } from "configureStore";
import { decodeJwt } from "jose";
import queryString from "query-string";
import { Dispatch } from "react";
import {
	receiveAdminGroup,
	requestAdminGroup,
} from "reducers/adminGroupReducer";
import { receiveAllCategories } from "reducers/allCategoriesReducer";
import { receiveDestinationConfig } from "reducers/destinationConfigReducer";
import { setAuthHeader } from "reducers/isAuthHeaderSetReducer";
import { receiveSessionMe, requestSessionMe } from "reducers/userReducer";
import { fetchRecoByHash } from ".";
import { adminApiPath, apiTsInstance, cachedApiTsInstance } from "../common";

async function invalidateCache(key: string) {
	return cachedApiTsInstance.post(`${adminApiPath}/invalidate-cache`, {
		key,
	});
}

function fetchAdmingroup(): AppThunk {
	return async (dispatch) => {
		dispatch(requestAdminGroup());
		return apiTsInstance
			.get<AdminGroup>(`${adminApiPath}/me`)
			.then(({ data }) => {
				dispatch(receiveAdminGroup({ data }));
			})
			.catch((err) => {
				dispatch(receiveAdminGroup({ error: err }));
			});
	};
}

export function fetchSessionMe() {
	return async (dispatch: any) => {
		dispatch(requestSessionMe());
		return apiTsInstance
			.get("session/me")
			.then((res) => dispatch(receiveSessionMe({ data: res.data })))
			.catch((error) => dispatch(receiveSessionMe({ error })));
	};
}

export function fetchDestinationConfig(destinationSlug: string): AppThunk {
	return async (dispatch) =>
		apiTsInstance
			.get<PopulatedDestination>(`destination/${destinationSlug}`)
			.then(({ data }) => {
				dispatch(receiveDestinationConfig(data));
				dispatch(receiveAllCategories({ data: data.categoryTree }));
			})
			.catch(() => {
				dispatch(receiveDestinationConfig(null));
			});
}

export function cleanDestinationCachedApi(destinationSlug: string) {
	return async () => {
		await invalidateCache(`cm::destination::${destinationSlug}`);
		return invalidateCache(`cm::destination_endpoint::${destinationSlug}`);
	};
}

export function cleanRecoCachedApi(id: number) {
	return cachedApiTsInstance.post(`${adminApiPath}/invalidate-cache`, {
		key: `recommendation::final::${id}`,
	});
}

export function login(token: string) {
	return (dispatch: Dispatch<any>) => {
		const decodedToken: DecodedToken = <DecodedToken>(
			(decodeJwt(token) as unknown)
		);
		apiTsInstance.defaults.headers.common.Authorization = `bearer ${token}`;
		cachedApiTsInstance.defaults.headers.common.Authorization = `bearer ${token}`;

		dispatch(fetchDestinationConfig(decodedToken.destinationSlug));
		dispatch(setAuthHeader());
		dispatch(fetchAdmingroup());
		dispatch(fetchSessionMe());
	};
}

export function parseLogonQueryString(url: string) {
	return async (dispatch: Dispatch<any>) => {
		const { id, token } = queryString.parse(url);
		if (token) {
			window.sessionStorage.setItem("auth", token as string);

			dispatch(login(token as string));
			if (id) {
				dispatch(fetchRecoByHash(id as string));
			}
		} else {
			return Promise.resolve();
		}
	};
}
