import { ERROR_CODES_MAP } from "@cruncho/cruncho-shared-types";
import { InformationVariant } from "../components/notificationComponents/NotificationsComponent";

const MAXIMUM_MESSAGE_LENGTH = 500;
const display = true;

function parseData(data: any): string {
	return typeof data === "string"
		? data.slice(0, MAXIMUM_MESSAGE_LENGTH)
		: data.message;
}

function handle401(
	{ status }: ErrorResponse,
	target: ErrorMessageTarget = { display },
	header: string = "",
): CmsNotification {
	const newHeader = `${header}: You're not logged in`;
	const variant: InformationVariant = "error";
	return {
		header: newHeader,
		status,
		message:
			'Please visit <your-destination>.cruncho.com and open the cms via the "manage" button',
		details: "",
		target,
		variant,
		persist: true,
	};
}

function handle403(
	{ data, status }: ErrorResponse,
	header: string = "Unauthorized",
): CmsNotification {
	const variant: InformationVariant = "error";
	return {
		header,
		status,
		message:
			"You are not authorized to perform the requested action. Please contact us if you think you received this message in error.",
		details: parseData(data),
		target: { display },
		variant,
		persist: true,
	};
}

function handle406(
	{ data, status }: ErrorResponse,
	target: ErrorMessageTarget = { display },
	header: string = "Validation Error",
): CmsNotification {
	const variant: InformationVariant = "error";
	return {
		header,
		status,
		message: parseData(data),
		details: parseData(data),
		target,
		variant,
		persist: true,
	};
}

function handle500(
	{ data }: ErrorResponse,
	target: ErrorMessageTarget = { display },
	header: string = "Server Error",
): CmsNotification {
	const variant: InformationVariant = "error";
	let details = parseData(data);

	const outsideOfPolygon =
		typeof data !== "string" && data.message === ERROR_CODES_MAP.OUTSIDE_POLYGON;

	if (outsideOfPolygon) {
		details = "The recommendation is outside polygon, please reselect address";
	}

	return {
		header,
		message: "Something went wrong. Please contact us to resolve the problem",
		details,
		target,
		variant,
		persist: true,
	};
}

function handle502(
	{ data, status }: ErrorResponse,
	target: ErrorMessageTarget = { display },
	header: string = "Bad GateWay",
): CmsNotification {
	const variant: InformationVariant = "error";
	return {
		header,
		status,
		message:
			"Unable to contact server, please try again later. Contact us if the problem persists",
		details: parseData(data),
		target,
		variant,
		persist: true,
	};
}

function handleNoResponse(
	error: any,
	target: ErrorMessageTarget = { display },
	header: string = "Connection Error",
): CmsNotification {
	const variant: InformationVariant = "error";
	if (error.toJSON && error.toJSON().message === "Network Error") {
		return {
			header,
			message:
				"Unable to reach server, please try again in a few minutes. Contact us if the problem persists",
			details: parseData(error.toJSON()),
			target,
			variant,
			persist: true,
		};
	}
	return {
		header,
		message: "Something went wrong. Please contact us if the problem persists",
		details: parseData(error),
		target,
		variant,
		persist: true,
	};
}

export interface CmsNotification {
	header: string;
	status?: number;
	message: string;
	details: string;
	persist: boolean;
	variant: InformationVariant;
	target?: ErrorMessageTarget;
}

export interface ErrorMessageTarget {
	display?: boolean;
	log?: boolean;
	alert?: boolean;
}

export interface Error {
	config: any;
	request: CmsNotification;
	isAxiosError: any;
	toJson: any;
	response: ErrorResponse;
}

export interface ErrorRequest {
	url: string;
	method: string;
}

export interface ErrorResponse {
	data:
		| {
				statusCode: number;
				error: string;
				message: string;
		  }
		| string;
	status: number;
}

export function parseErrorMessage(
	error: any,
	header: string = "Unknown error",
	target: ErrorMessageTarget = { display },
): CmsNotification {
	if (!error.response) {
		return handleNoResponse(error, target, header);
	}
	const response = error.response;
	const { data, status } = response;
	/**
	 * Test all of these handlers  with  one async.
	 *
	 * Then test all of the asyncs with at least one of the handlers
	 *
	 * */
	switch (status) {
		case 400: // fall through, while migrating 406 errors to 400 errors for validation errors
		case 406:
			return handle406(response, target, header);
		case 401: // tested
			return handle401(response, target, header);
		case 403: // Tested
			return handle403(response, header);
		case 500:
			return handle500(response, target, header);
		case 502:
			return handle502(response, target, header);
		default:
			// Tested
			return {
				header,
				status,
				message: "Something went wrong. Please contact us to resolve the problem",
				details: parseData(data),
				variant: "error",
				persist: true,
			};
	}
}
