import {
	CmsNotification,
	ErrorMessageTarget,
	parseErrorMessage,
} from "./parseError";

const display = true;

export function createErrorNotification(
	header: string,
	error: any,
	target: ErrorMessageTarget = { display },
): CmsNotification {
	return parseErrorMessage(error, header, target);
}

export default createErrorNotification;
