import { configs } from "common";

export function readEnvVar(varName: string, defaultValue: string = "") {
	return process.env[varName] || defaultValue;
}

export function getConfigEnv() {
	const env =
		process.env.TARGET_ENV ?? process.env.REACT_APP_TARGET_ENV ?? "staging";
	return configs[env.trim()];
}
