import { L1 } from "@cruncho/cruncho-shared-types";
import { createSlice } from "@reduxjs/toolkit";

export interface EsSearchResult {
	id: string;
	name: string;
	l1: L1;
}
export type MyRecosState = EsSearchResult[];

const myRecosSlice = createSlice({
	name: "myRecos",
	initialState: [] as MyRecosState,
	reducers: {
		receiveMyRecos(_state, action) {
			return action.payload;
		},
	},
});

export const { receiveMyRecos } = myRecosSlice.actions;

export default myRecosSlice.reducer;
