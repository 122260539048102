import { z } from "zod";
import { dateSchema } from "../utilities";
import { LiveEvent, liveEventSchema } from "./liveEvent";

export const venueSchema = z.object({
	_id: z.string(),
	name: z.string(),
	destinationSlug: z.string(),
	address: z.string(),
	placeId: z.string(),
	mapCoordinates: z.object({
		lat: z.number(),
		lng: z.number(),
	}),
});

export type Venue = z.infer<typeof venueSchema>;

export interface ActionLog {
	/** Mongo id */
	_id: string;

	/** Email or userId of the one made the change  */
	user: string;

	/** Which service/app this log came from */
	service: "EVENT_MANAGER" | "CMS";

	/** When it happened */
	timestamp: Date;

	/** what happened */
	action:
		| "APPROVAL"
		| "CREATION"
		| "DELETE"
		| "FACEBOOK PREFILL"
		| "REJECTION"
		| "SUBMISSION"
		| "UPDATE"
		| "UPDATE OPENING HOURS";

	/** Event for the moment, recos when the CMS will be fixed one day hopefully */
	entityId: string;

	/** Self explanatory */
	destinationSlug: string;

	delta?: Delta[];

	/** stringified extra json information */
	json?: string;
}

export interface CreateActionLog extends Omit<ActionLog, "_id"> {}

export interface Delta {
	field: keyof Omit<LiveEvent, "_id">;
	before: LiveEvent[keyof LiveEvent];
	after: LiveEvent[keyof LiveEvent];
}

export const liveEventTemplateSchema = z.object({
	_id: z.string(),
	templateName: z.string(), // templateName and not only name to avoid conflicts with template.name
	destinationSlug: z.string(),
	owner: z.string(),
	groups: z.array(z.string()).optional(),
	createdAt: dateSchema,
	updatedAt: dateSchema.optional(),
	template: liveEventSchema.partial(),
});

export type LiveEventTemplate = z.infer<typeof liveEventTemplateSchema>;
