import { readEnvVar } from "./configUtils";

export function consoleLogger<T>(
	obj: T,
	title: string = "",
	useBigSep: boolean = false,
): T {
	if (readEnvVar("NODE_ENV") !== "production") {
		const bigSep = "\n\n----------------------------------\n\n";
		if (useBigSep) {
			console.log(bigSep);
		}
		if (title) {
			console.log(title);
		}

		// Need to try catch in case of cyclic object values...
		try {
			console.log(JSON.stringify(obj, null, 2));
		} catch (error) {
			console.warn("Error while stringifying", error, "raw value", obj);
		}

		if (useBigSep) {
			console.log(bigSep);
		}
	}
	return obj;
}

export default consoleLogger;
