import { LandingPageCategoryConfiguration } from "@cruncho/cruncho-shared-types";

/**
 * Get the React list key of a carousel
 * @param config Carousel configuration
 * @returns A string
 */
export const getCarouselKey = (config: LandingPageCategoryConfiguration) => {
	const newL2 = [...(config.l2 ?? [])];
	newL2.sort();

	const newL3 = [...(config.l3 ?? [])];
	newL3.sort();

	return `L2:[${newL2.join(",")}]-L3:[${newL3.join(",")}]`;
};

/**
 * Compares two arrays of strings. They are considered equal if they have the same elements or if they both in the set { undefined, [] }.
 * @param lhs First array of strings
 * @param rhs Second array of strings
 * @returns true if they are almost equal, false otherwise
 */
const areAlmostSameArrays = (
	lhs: string[] | undefined,
	rhs: string[] | undefined,
) => {
	if (!lhs && !rhs) return true;
	if (lhs && lhs.length === 0 && !rhs) return true;
	if (!lhs && rhs && rhs.length === 0) return true;
	if (lhs && lhs.length === 0 && rhs && rhs.length === 0) return true;

	if (!lhs || lhs.length === 0 || !rhs || rhs.length === 0) return false;

	const newLhs = [...lhs];
	newLhs.sort();
	const newRhs = [...rhs];
	newRhs.sort();
	return newLhs.join(",") === newRhs.sort().join(",");
};

/**
 * Compares two carousel configurations. They are considered equal if their l2 and l3 fields are almost equal.
 * @param lhs First carousel configuration
 * @param rhs Second carousel configuration
 * @returns true if they describe the same carousel, false otherwise
 */
export const areSameConfigs = (
	lhs: LandingPageCategoryConfiguration,
	rhs: LandingPageCategoryConfiguration,
) => areAlmostSameArrays(lhs.l2, rhs.l2) && areAlmostSameArrays(lhs.l3, rhs.l3);
