/**
 * File defining available languages and related zod schemas
 */
import { z } from "zod";

export const APP_LANGUAGE_CODES = [
	"da",
	"de",
	"en",
	"en-AU",
	"en-US",
	"es",
	"fr",
	"it",
	"nb",
	"nl",
	"pt",
	"ro",
	"ru",
	"sv",
	"zh_Hans",
	"zh_Hant",
] as const;

export const appLanguageCodeSchema = z.enum(APP_LANGUAGE_CODES);

/**
 * Country codes used in the apps
 */
export type AppLanguageCode = z.infer<typeof appLanguageCodeSchema>;

export const APP_LANGUAGES = [
	"ca-ES",
	"da-DK",
	"de-DE",
	"en-AU",
	"en-GB",
	"en-US",
	"es-ES",
	"fr-FR",
	"it-IT",
	"nb-NO",
	"nl-NL",
	"nl-BE",
	"pt-PT",
	"ro-RO",
	"ru-RU",
	"sv-SE",
	"zh-Hans-CN",
	"zh-Hant-TW",
] as const;

export const appLanguageSchema = z.enum(APP_LANGUAGES);

/**
 * Locales used in the apps
 */
export type AppLanguage = z.infer<typeof appLanguageSchema>;

export const APP_LANGUAGE_CODES_MAP: Record<AppLanguageCode, AppLanguage> = {
	da: "da-DK",
	de: "de-DE",
	en: "en-GB",
	"en-AU": "en-AU",
	"en-US": "en-US",
	es: "es-ES",
	fr: "fr-FR",
	it: "it-IT",
	nb: "nb-NO",
	nl: "nl-NL",
	pt: "pt-PT",
	ro: "ro-RO",
	ru: "ru-RU",
	sv: "sv-SE",
	zh_Hans: "zh-Hans-CN",
	zh_Hant: "zh-Hant-TW",
};

export function appLanguageCodeToAppLanguage(
	code: AppLanguageCode,
): AppLanguage {
	return APP_LANGUAGE_CODES_MAP[code];
}

/**
 * Enumeration of languages according to ISO 639-1
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export const languagesISO639_1_Schema = z.enum([
	"aa",
	"ab",
	"ae",
	"af",
	"ak",
	"am",
	"an",
	"ar",
	"as",
	"av",
	"ay",
	"az",
	"ba",
	"be",
	"bg",
	"bi",
	"bm",
	"bn",
	"bo",
	"br",
	"bs",
	"ca",
	"ce",
	"ch",
	"co",
	"cr",
	"cs",
	"cu",
	"cv",
	"cy",
	"da",
	"de",
	"dv",
	"dz",
	"ee",
	"el",
	"en",
	"eo",
	"es",
	"et",
	"eu",
	"fa",
	"ff",
	"fi",
	"fj",
	"fo",
	"fr",
	"fy",
	"ga",
	"gd",
	"gl",
	"gn",
	"gu",
	"gv",
	"ha",
	"he",
	"hi",
	"ho",
	"hr",
	"ht",
	"hu",
	"hy",
	"hz",
	"ia",
	"id",
	"ie",
	"ig",
	"ii",
	"ik",
	"io",
	"is",
	"it",
	"iu",
	"ja",
	"jv",
	"ka",
	"kg",
	"ki",
	"kj",
	"kk",
	"kl",
	"km",
	"kn",
	"ko",
	"kr",
	"ks",
	"ku",
	"kv",
	"kw",
	"ky",
	"la",
	"lb",
	"lg",
	"li",
	"ln",
	"lo",
	"lt",
	"lu",
	"lv",
	"mg",
	"mh",
	"mi",
	"mk",
	"ml",
	"mn",
	"mr",
	"ms",
	"mt",
	"my",
	"na",
	"nb",
	"nd",
	"ne",
	"ng",
	"nl",
	"nn",
	"no",
	"nr",
	"nv",
	"ny",
	"oc",
	"oj",
	"om",
	"or",
	"os",
	"pa",
	"pi",
	"pl",
	"ps",
	"pt",
	"qu",
	"rm",
	"rn",
	"ro",
	"ru",
	"rw",
	"sa",
	"sc",
	"sd",
	"se",
	"sg",
	"si",
	"sk",
	"sl",
	"sm",
	"sn",
	"so",
	"sq",
	"sr",
	"ss",
	"st",
	"su",
	"sv",
	"sw",
	"ta",
	"te",
	"tg",
	"th",
	"ti",
	"tk",
	"tl",
	"tn",
	"to",
	"tr",
	"ts",
	"tt",
	"tw",
	"ty",
	"ug",
	"uk",
	"ur",
	"uz",
	"ve",
	"vi",
	"vo",
	"wa",
	"wo",
	"xh",
	"yi",
	"yo",
	"za",
	"zh",
	"zu",
]);

export type languagesISO639 = z.infer<typeof languagesISO639_1_Schema>;

/**
 * The language codes used internally by react phone input
 * They don't provide their own types...
 * If one day they provide them, use them instead of this.
 *
 * Source:
 * https://github.com/bl00mber/react-phone-input-2/blob/master/src/rawCountries.js
 * https://github.com/bl00mber/react-phone-input-2/blob/master/src/rawTerritories.js
 */
export const REACT_PHONE_INPUT_LANGUAGE_CODES = [
	"ad",
	"ae",
	"af",
	"ag",
	"ai",
	"al",
	"am",
	"ao",
	"ar",
	"as",
	"at",
	"au",
	"aw",
	"az",
	"ba",
	"bb",
	"bd",
	"be",
	"bf",
	"bg",
	"bh",
	"bi",
	"bj",
	"bl",
	"bm",
	"bn",
	"bo",
	"bq",
	"br",
	"bs",
	"bt",
	"bw",
	"by",
	"bz",
	"ca",
	"cd",
	"cf",
	"cg",
	"ch",
	"ci",
	"ck",
	"cl",
	"cm",
	"cn",
	"co",
	"cr",
	"cu",
	"cv",
	"cw",
	"cy",
	"cz",
	"de",
	"dj",
	"dk",
	"dm",
	"do",
	"dz",
	"ec",
	"ee",
	"eg",
	"er",
	"es",
	"et",
	"fi",
	"fj",
	"fk",
	"fm",
	"fo",
	"fr",
	"ga",
	"gb",
	"gd",
	"ge",
	"gf",
	"gh",
	"gi",
	"gl",
	"gm",
	"gn",
	"gp",
	"gq",
	"gr",
	"gt",
	"gu",
	"gw",
	"gy",
	"hk",
	"hn",
	"hr",
	"ht",
	"hu",
	"id",
	"ie",
	"il",
	"in",
	"io",
	"iq",
	"ir",
	"is",
	"it",
	"je",
	"jm",
	"jo",
	"jp",
	"ke",
	"kg",
	"kh",
	"ki",
	"km",
	"kn",
	"kp",
	"kr",
	"kw",
	"ky",
	"kz",
	"la",
	"lb",
	"lc",
	"li",
	"lk",
	"lr",
	"ls",
	"lt",
	"lu",
	"lv",
	"ly",
	"ma",
	"mc",
	"md",
	"me",
	"mf",
	"mg",
	"mh",
	"mk",
	"ml",
	"mm",
	"mn",
	"mo",
	"mp",
	"mq",
	"mr",
	"ms",
	"mt",
	"mu",
	"mv",
	"mw",
	"mx",
	"my",
	"mz",
	"na",
	"nc",
	"ne",
	"nf",
	"ng",
	"ni",
	"nl",
	"no",
	"np",
	"nr",
	"nu",
	"nz",
	"om",
	"pa",
	"pe",
	"pf",
	"pg",
	"ph",
	"pk",
	"pl",
	"pm",
	"pr",
	"ps",
	"pt",
	"pw",
	"py",
	"qa",
	"re",
	"ro",
	"rs",
	"ru",
	"rw",
	"sa",
	"sb",
	"sc",
	"sd",
	"se",
	"sg",
	"sh",
	"si",
	"sk",
	"sl",
	"sm",
	"sn",
	"so",
	"sr",
	"ss",
	"st",
	"sv",
	"sx",
	"sy",
	"sz",
	"tc",
	"td",
	"tg",
	"th",
	"tj",
	"tk",
	"tl",
	"tm",
	"tn",
	"to",
	"tr",
	"tt",
	"tv",
	"tw",
	"tz",
	"ua",
	"ug",
	"us",
	"uy",
	"uz",
	"va",
	"vc",
	"ve",
	"vg",
	"vi",
	"vn",
	"vu",
	"wf",
	"ws",
	"xk",
	"ye",
	"za",
	"zm",
	"zw",
] as const;

export const reactPhoneInputLanguageCodeSchema = z.enum(
	REACT_PHONE_INPUT_LANGUAGE_CODES,
);

/*
 * Country codes we feed react phone input with
 */
export type ReactPhoneInputLanguageCode = z.infer<
	typeof reactPhoneInputLanguageCodeSchema
>;

const tripAdvisorLanguages = [
	"ae_AE",
	"ar",
	"cs",
	"da",
	"de_AT",
	"de",
	"el",
	"en_AU",
	"en_CA",
	"en_HK",
	"en_IE",
	"en_IN",
	"en_MY",
	"en_NZ",
	"en_PH",
	"en_SG",
	"en_UK",
	"en_ZA",
	"en",
	"es_AR",
	"es_CL",
	"es_CO",
	"es_MX",
	"es_PE",
	"es_VE",
	"es",
	"fi",
	"fr_BE",
	"fr_CA",
	"fr_CH",
	"fr",
	"hu",
	"in",
	"it_CH",
	"it",
	"iw",
	"ja",
	"ko",
	"nl",
	"nl_BE",
	"no",
	"pl",
	"pt_PT",
	"pt",
	"ru",
	"sk",
	"sr",
	"sv",
	"th",
	"tr",
	"vi",
	"zh_CN",
	"zh_TW",
	"zh_HK",
	"zh",
] as const;

const languagesZodEnum = z.enum(tripAdvisorLanguages);
export type TripAdvisorLanguagesEnum = z.infer<typeof languagesZodEnum>;

// Language to have section translated
// The order will determine the language displayed by default
export const SOULCIRCUS_TRANSLATION_LANGUAGES = ["en", "sv"] as const;

export const soulcircusTranslationLanguagesSchema = z.enum(
	SOULCIRCUS_TRANSLATION_LANGUAGES,
);
export type soulcircusTranslationLanguages = z.infer<
	typeof soulcircusTranslationLanguagesSchema
>;
