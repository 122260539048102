import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface HideBatchState {
	succeeded: HideBatchResult[];
	failed: HideBatchResult[];
	processing: boolean;
}

export interface HideBatchResult {
	id: number;
	error: false | any;
}

const hideBatchSlice = createSlice({
	name: "hideBatch",
	initialState: {
		succeeded: [],
		failed: [],
		processing: false,
	} as HideBatchState,
	reducers: {
		hideBatchBegin(state) {
			state.processing = true;
		},
		hideBatchCompleted(state, action: PayloadAction<HideBatchResult[]>) {
			state.processing = false;
			state.succeeded = action.payload.filter((res) => !res.error);
			state.failed = action.payload.filter((res) => res.error);
		},
	},
});

export const { hideBatchBegin, hideBatchCompleted } = hideBatchSlice.actions;

export default hideBatchSlice.reducer;
