/**
 * Promise wrapper in order to have a minimum execution time.
 *
 * This could be used to create more friendly UI for example, so that content doesn't flash too quickly.
 *
 * @param promise Promise to wrap
 * @param delay Minimum execution time in milliseconds.
 *
 * @returns A promise whose execution time will be at least {@link delay}ms.
 */
export async function waitAtLeast<T>(
	promise: Promise<T>,
	delay: number,
): Promise<T> {
	if (delay < 0) {
		throw new Error("delay should be positive");
	}

	const start = new Date().getTime();
	const result = await promise;
	const duration = new Date().getTime() - start;

	if (duration < delay) {
		return new Promise((resolve) => {
			setTimeout(() => resolve(result), delay - duration);
		});
	}

	return result;
}
