import { z } from "zod";

namespace ProductType {
	export const SUBSCRIPTION = "subscription";
	export const PRACTITIONER_SESSION_SLOT = "practitioner_session_slot";
}

export type ProductTypeOption =
	| typeof ProductType.SUBSCRIPTION
	| typeof ProductType.PRACTITIONER_SESSION_SLOT;

export const ProductTypeEnum = z.enum([
	ProductType.SUBSCRIPTION,
	ProductType.PRACTITIONER_SESSION_SLOT,
]);

namespace Reccuring {
	export const YEARLY = "year";
	export const MONTHLY = "month";
	export const NO = "no";
}

export type ReccuringOption =
	| typeof Reccuring.YEARLY
	| typeof Reccuring.MONTHLY
	| typeof Reccuring.NO;

export const ReccuringEnum = z.enum([
	Reccuring.YEARLY,
	Reccuring.MONTHLY,
	Reccuring.NO,
]);

export const productPermissionsSchema = z.object({
	answerReview: z.boolean().optional(),
});

export const soulcircusProductSugarcoatedSchema = z.object({
	name: z.string(),
	description: z.string(),
	price: z.number(),
	promotionPrice: z.number().optional(),
	recurring: ReccuringEnum,
	type: ProductTypeEnum,
	subscriptionLevel: z.number().optional(),
	bookingLimit: z.number().optional(),
	active: z.boolean(),
	permissions: productPermissionsSchema.optional(),
});

export const soulcircusProductSchema = z.object({
	_id: z.string().optional(),
	stripeId: z.string(),
	priceId: z.string(),
	name: z.string(),
	description: z.string(),
	price: z.number(),
	promotionPrice: z.number().optional(),
	recurring: ReccuringEnum,
	type: ProductTypeEnum,
	subscriptionLevel: z.number().optional(),
	bookingLimit: z.number().optional(),
	active: z.boolean(),
	permissions: productPermissionsSchema.optional(),
});

export type ProductPermissions = z.infer<typeof productPermissionsSchema>;

export type SoulcircusProductSugarcoated = z.infer<
	typeof soulcircusProductSugarcoatedSchema
>;
export type SoulcircusProduct = z.infer<typeof soulcircusProductSchema>;
