import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { fetchRecoByHash, login } from "./actions";
import App from "./App";
import { store } from "./configureStore";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { readEnvVar } from "./utils/configUtils";
import { messageReceiver } from "./utils/messageService";

const auth =
	readEnvVar("NODE_ENV") === "development" || readEnvVar("NODE_ENV") === "local"
		? readEnvVar("REACT_APP_DEV_LOGIN")
		: window.sessionStorage.getItem("auth");
if (auth) {
	store.dispatch(login(auth));
}

if (
	readEnvVar("NODE_ENV") === "development" ||
	readEnvVar("NODE_ENV") === "local"
) {
	const preloadedReco = readEnvVar("REACT_APP_PRELOADED_RECO");
	console.log("Developpement mode, starting on preloaded reco", preloadedReco);
	if (preloadedReco) {
		store.dispatch(fetchRecoByHash(preloadedReco));
	}
}

window.addEventListener("message", messageReceiver(store), false);

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);

serviceWorker.unregister();
