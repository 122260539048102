import {
	Photo,
	Review,
	StructuredReco,
	Video,
	Videos,
} from "@cruncho/cruncho-shared-types";
import { concat } from "lodash";
import { setCategories } from "reducers/propertyReducers/categoriesReducer";
import {
	updateHide,
	updateHideReason,
} from "reducers/propertyReducers/hideSettingReducer";
import { updatePinned } from "reducers/propertyReducers/isPinnedReducer";
import { updateSponsored } from "reducers/propertyReducers/sponsoredSettingReducer";
import { initViatorData } from "reducers/propertyReducers/viatorDataReducer";
import { AppState, AppThunk } from "../configureStore";
import { propertyUpdate } from "./selectedRecoActions";

export function removeElementAt<T>(array: Array<T>, index: number) {
	return concat(array.slice(0, index), array.slice(index + 1, array.length));
}

/**
 * ACTION CREATORS
 *
 */
export function addPhoto(photo: Photo) {
	return (dispatch: any, getState: () => AppState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const photos: Photo[] = unsavedReco.photos;
			return dispatch(propertyUpdate("photos", [photo, ...photos]));
		}
	};
}

export function removePhotoByIndex(index: number): AppThunk {
	return async (dispatch, getState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const photos: Photo[] = unsavedReco.photos;
			return dispatch(propertyUpdate("photos", removeElementAt(photos, index)));
		}
	};
}

export function setFirstPhoto(index: number): AppThunk {
	return async (dispatch, getState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const photos: Photo[] = unsavedReco.photos;
			const first = photos[index];
			return dispatch(
				propertyUpdate("photos", concat([first], removeElementAt(photos, index))),
			);
		}
	};
}

export function setFirstVideo(index: number) {
	return (dispatch: any, getState: () => AppState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const videos: Videos = unsavedReco.videos || [];
			const first = videos[index];
			return dispatch(
				propertyUpdate("videos", concat([first], removeElementAt(videos, index))),
			);
		}
	};
}

export function addVideo(video: Video) {
	return (dispatch: any, getState: () => AppState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const videos: Videos = unsavedReco.videos || [];
			return dispatch(propertyUpdate("videos", [video, ...videos]));
		}
	};
}

export function removeVideoByIndex(index: number) {
	return (dispatch: any, getState: () => AppState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const videos: Videos = unsavedReco.videos || [];
			return dispatch(propertyUpdate("videos", removeElementAt(videos, index)));
		}
	};
}

export function removeCommentByIndex(index: number): AppThunk {
	return (dispatch, getState) => {
		const unsavedReco = getState().selectedReco.unsavedModifiedReco;
		if (unsavedReco) {
			const comments: Review[] = unsavedReco.reviews;
			return dispatch(propertyUpdate("reviews", removeElementAt(comments, index)));
		}
	};
}

export function initProperties(reco: StructuredReco): AppThunk {
	return async (dispatch) => {
		dispatch(initViatorData(reco.viatorData));
		dispatch(updateHideReason(reco.hideReason || ""));
		dispatch(updateHide(!!reco.hide));
		dispatch(updateSponsored(reco.isSponsored!));
		dispatch(updatePinned(reco.isPinned!));
		dispatch(setCategories(reco.categories));
	};
}
