import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface SponsoredSettingState {
	isSponsored: boolean;
}

const sponsoredSettingSlice = createSlice({
	name: "sponsoredSetting",
	initialState: { isSponsored: false } as SponsoredSettingState,
	reducers: {
		updateSponsored(state, action: PayloadAction<boolean | undefined>) {
			state.isSponsored = !!action.payload;
		},
	},
});

export const { updateSponsored } = sponsoredSettingSlice.actions;

export default sponsoredSettingSlice.reducer;
