import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CmsNotification } from "../notificationCreators/parseError";

type NotificationState = CmsNotification[];

const notificationsSlice = createSlice({
	name: "notifications",
	initialState: [] as NotificationState,
	reducers: {
		dequeueNotification(state) {
			return state.length > 0 ? state.slice(1, state.length) : state;
		},
		enqueueNotification(state, action: PayloadAction<CmsNotification>) {
			state.push(action.payload);
			return state;
		},
	},
});

export const { dequeueNotification, enqueueNotification } =
	notificationsSlice.actions;

export default notificationsSlice.reducer;
