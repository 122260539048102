import { SessionMe } from "@cruncho/cruncho-shared-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface UserState {
	async: AsyncCall;
	sessionMe?: SessionMe;
}

const userSlice = createSlice({
	name: "user",
	initialState: { async: initAsync() } as UserState,
	reducers: {
		requestSessionMe(state) {
			state.async = startAsync();
		},
		receiveSessionMe(
			state,
			action: PayloadAction<{ data?: SessionMe; error?: any }>,
		) {
			if (action.payload.data) state.sessionMe = action.payload.data;
			state.async = handleResponse(action.payload);
		},
	},
});

export const { requestSessionMe, receiveSessionMe } = userSlice.actions;

export default userSlice.reducer;
