export interface AsyncCall {
	isFetching: boolean;
	notify: boolean;
	error: any;
}

export const startAsync: () => AsyncCall = () => ({
	isFetching: true,
	notify: true,
	error: false,
});

export const initAsync: () => AsyncCall = () => ({
	isFetching: false,
	notify: false,
	error: false,
});

export function handleResponse({ error }: { error?: any }): AsyncCall {
	return {
		isFetching: false,
		notify: true,
		error: error || false,
	};
}
