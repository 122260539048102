import { StructuredReco, ViatorData } from "@cruncho/cruncho-shared-types";
import { isUndefined, omitBy } from "lodash";
import { createSelector } from "reselect";

import { AppState } from "../configureStore";

export * from "./boostSelector";

function getSelectedRecoUnsavedModifiedReco(
	state: AppState,
): StructuredReco | false {
	return state.selectedReco.unsavedModifiedReco;
}

function getCategories(state: AppState): string[] {
	return state.categories;
}

export function getViatorData(state: AppState): ViatorData | undefined {
	return Object.keys(state.viatorData).length > 0 ? state.viatorData : undefined;
}

export function getHide(state: AppState): boolean {
	return state.hideSetting.hide;
}

export function getHideReason(state: AppState): string | undefined {
	return state.hideSetting.hideReason;
}
export function getSponsored(state: AppState): boolean {
	return state.sponsoredSetting.isSponsored;
}
export function getPinned(state: AppState): boolean {
	return state.pinnedSetting.isPinned;
}
export const getUnsavedModifiedReco = createSelector(
	[
		getSelectedRecoUnsavedModifiedReco,
		getViatorData,
		getHide,
		getHideReason,
		getCategories,
		getSponsored,
		getPinned,
	],
	(
		unsavedModifiedreco,
		viatorData,
		hide,
		hideReason,
		categories,
		isSponsored,
		isPinned,
	) => {
		if (unsavedModifiedreco) {
			return omitBy<StructuredReco>(
				{
					...unsavedModifiedreco,
					viatorData,
					hide,
					hideReason,
					categories,
					isSponsored,
					isPinned,
				},
				isUndefined,
			);
		}
		return false;
	},
);
