import { z } from "zod";
import { currencyCodeSchema } from "./currencies";
import { dateSchema } from "./utilities";

const enumAvailableSchema = z.enum([
	"available",
	"pending",
	"unavailable",
	"unknown",
]);

const tripadvisorErrorSchema = z.object({
	code: z.number(),
	messageKey: z.string(),
	message: z.string(),
	payload: z.object({}),
});

export type TripadvisorError = z.infer<typeof tripadvisorErrorSchema>;

export const tripadvisorApiResponseSchema = z.object({
	success: z
		.object({
			requestId: z.string(),
			results: z.array(
				z.object({
					hotelId: z.string(),
					strikeThroughDisplayPrice: z.string().describe("$150").optional(),
					availability: enumAvailableSchema,
					offers: z.array(
						z
							.object({
								availability: enumAvailableSchema,
								displayName: z.string(),
								displayPrice: z.string().describe("$150").optional(),
								price: z.number().optional(),
								logo: z.string().url(),
								clickUrl: z.string().url(),
							})
							.nullable(),
					),
				}),
			),
			isComplete: z.boolean(),
			pricingType: z.enum(["base"]),
			pollingLink: z.string().optional(),
		})
		.nullable()
		.optional(),
	errors: z.array(tripadvisorErrorSchema).optional(),
	warnings: z.array(z.any()).optional(),
	completedAt: dateSchema.optional(),
	offers: z
		.array(
			z.object({
				availability: enumAvailableSchema,
				displayName: z.string(),
				logo: z.string().url(),
				clickUrl: z.string().url(),
			}),
		)
		.optional(),
});

export type TripadvisorSyncResponse = z.infer<
	typeof tripadvisorApiResponseSchema
>;

export const tripadvisorParamsSchema = z.object({
	recoId: z.string(),
	startDate: z.string(),
	endDate: z.string(),
	currency: currencyCodeSchema.optional(),
});

export type TripadvisorParams = z.infer<typeof tripadvisorParamsSchema>;
