export * from "./authTypes";
export * from "./autocomplete";
export * from "./bugReports";
export * from "./calibrationSlugs";
export * from "./categories";
export * from "./countries";
export * from "./currencies";
export * from "./destination";
export * from "./elastic";
export * from "./errorCode";
export * from "./envConfig";
export * from "./eventmanager";
export * from "./eventmanagerSchemas";
export * from "./format";
export * from "./geometry";
export * from "./languages";
export * from "./mongoClients";
export * from "./soulcircus";
export * from "./query";
export * from "./quiz";
export * from "./recommendation";
export * from "./reverseGeocoding";
export * from "./rsvp";
export * from "./scrapers";
export * from "./searchTypes";
export * from "./timezones";
export * from "./tripadvisor";
export * from "./utilities";

export type DeepPartial<T> = T extends object
	? { [K in keyof T]?: DeepPartial<T[K]> }
	: T;

/**
 * An utility type to easily make a property optional.
 */
export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export interface TypeMapping {
	l1_slug: string;
	l1_display_name: string;
	l2_slug: string;
	l2_display_name: string;
	l3_slug: string;
	l3_display_name: string;
}

/**
 * Generic type for translation functions, since not every project has i18n installed.
 */
export type TranslationFunction = any;

/**
 * A time interval linked to a day
 */
export interface TimePeriod {
	/**
	 * Day id: 0 === Monday
	 */
	id: number;
	closeTime: string;
	openTime: string;
}

/**
 * Openings hours during a day
 */
export interface DayOpenings {
	/**
	 * Day id: 0 === Monday
	 */
	id: number;

	/**
	 * Array of tuple of openings: first element is opening hour, second element is closing hour
	 */
	openings: Array<[openingHour: string, endHour: string]>;
}
