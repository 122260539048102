import { ViatorData } from "@cruncho/cruncho-shared-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const viatorDataSlice = createSlice({
	name: "viatorData",
	initialState: {} as ViatorData,
	reducers: {
		initViatorData(state, action: PayloadAction<ViatorData | undefined>) {
			if (action.payload) return action.payload;
			return state;
		},
		updateViatorData(state, action: PayloadAction<ViatorData>) {
			return { ...state, ...action.payload };
		},
	},
});

export const { initViatorData, updateViatorData } = viatorDataSlice.actions;

export default viatorDataSlice.reducer;
