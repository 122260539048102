import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
	AsyncCall,
	handleResponse,
	initAsync,
	startAsync,
} from "./reducerHelpers/asyncHelper";

export interface DecodedHashState {
	decodedHash: number;
	async: AsyncCall;
}

const decodedHashSlice = createSlice({
	name: "decodedHash",
	initialState: { decodedHash: 0, async: initAsync() } as DecodedHashState,
	reducers: {
		requestIdFromHash(state) {
			state.async = startAsync();
		},
		receiveIdFromHash(
			state,
			action: PayloadAction<{ data?: number; error?: any }>,
		) {
			if (action.payload.data) state.decodedHash = action.payload.data;
			state.async = handleResponse(action.payload);
		},
	},
});

export const { requestIdFromHash, receiveIdFromHash } =
	decodedHashSlice.actions;

export default decodedHashSlice.reducer;
