import { EnvConfig } from "@cruncho/cruncho-shared-types";
import { format } from "date-fns";

/**
 * Create a link to the live events cruncho page for the given subdomain
 * @param subdomainMatch
 * @param crunchoSubdomain
 * @param apiRecoId
 * @returns
 */
export function getCrunchoLink(
	subdomainMatch: string,
	crunchoSubdomain: string,
	apiRecoId?: string,
): string {
	if (apiRecoId)
		return `https://${
			subdomainMatch.includes("|") ? subdomainMatch.split("|")[0] : subdomainMatch
		}${crunchoSubdomain}/place/${apiRecoId}`.replace("..", ".");
	return `https://${
		subdomainMatch.includes("|") ? subdomainMatch.split("|")[0] : subdomainMatch
	}${crunchoSubdomain}`.replace("..", ".");
}

/**
 * Detect if a link is from ticket TicketMaster.
 *
 * @param link Link to check
 */
export function isTicketMasterLink(link: string): boolean {
	return Boolean(link.match(/\.ticketmaster\.(com|se|es|fr|dk|de)/));
}

/**
 * Create a partner URL with the necessary URL params.
 *
 * @param url Base URL
 * @param forkTrackingCode Fork client tracking code (default is Corsematin's)
 * @param params Extra params
 * @returns Partner link
 */
export const getPartnerURL = (
	url: string,
	forkTrackingCode: string = "66455-052",
	params?: {
		/**
		 * Date range for TripAdvisor
		 */
		dateRange?: { from: Date; to: Date };
	},
) => {
	if (url.includes("thefork")) {
		return `${url}?cc=${forkTrackingCode}`;
	}

	if (url.includes("tripadvisor") && params?.dateRange) {
		if (url.includes("staydates")) {
			// nothing to do
			return url;
		}

		const urlParams = `staydates=${format(
			params.dateRange.from,
			"yyyy_MM_dd",
		)}_${format(params.dateRange.to, "yyyy_MM_dd")}`;

		if (url.includes("?")) {
			// Already some parameters, just add the staydates
			return `${url}&${urlParams}`;
		}
		// else add the ? to start the parameters
		return `${url}?${urlParams}`;
	}

	return url;
};

export const buildRSVPUrl = (encodedId: string, env: EnvConfig) =>
	`${env.rsvpClient}reserve/${encodedId}`;
