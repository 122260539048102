import { BaseReco, L1, RecoApiEnum } from "@cruncho/cruncho-shared-types";
import { AppThunk } from "configureStore";
import { receiveMyRecos } from "reducers/myRecosReducer";
import { fetchRecoByHash } from ".";
import { adminApiPath, apiTsInstance } from "../common";

export const REQUEST_NEW_RECO = "REQUEST_NEW_RECO";
export const RECEIVE_NEW_RECO = "RECEIVE_NEW_RECO";

export function createNewReco(l1: L1): AppThunk {
	return async (dispatch, getState) => {
		const state = getState();
		const config = state.destinationConfig;

		if (!config) {
			throw new Error("Destination config is not exist");
		}

		const {
			_id: destinationSlug,
			features: { eventManagerFeatures },
		} = config;

		if (!eventManagerFeatures?.defaultLocation) {
			throw new Error("Default location is not exist");
		}

		const { defaultLocation } = eventManagerFeatures;

		const geometry = {
			lat: defaultLocation.coordinates.lat,
			lng: defaultLocation.coordinates.lng,
		};

		const name = "New Recommendation";
		const newReco: Omit<BaseReco, "id"> = {
			name,
			shouldMatch: false,
			categories: [l1],
			apiRatings: [],
			availableApis: [RecoApiEnum.crunchoReco],
			destinationSlug,
			isFree: false,
			l1,
			online: false,
			photos: [],
			reviews: [],
			tag: "CMS",
			geometry,
		};
		const { data } = await apiTsInstance.post("/recommendations/", newReco);

		dispatch(fetchRecoByHash(data.id));
	};
}

export function fetchMyRecos(): AppThunk {
	return (dispatch) => {
		apiTsInstance
			.get(`${adminApiPath}/my-recos`)
			.then(({ data }) => dispatch(receiveMyRecos(data === "" ? [] : data)))
			.catch((err) => {
				console.log("Error when receving my Recos");
				console.error(err);
			});
	};
}
