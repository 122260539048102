import { z } from "zod";

export const BUG_REPORT_PRODUCTS = {
	API: "API",
	CMS: "CMS",
	MAIN: "cruncho.com",
	EM: "Event Manager",
	EAPI: "Events API",
	EC: "Event Calendar",
	GUIDE: "Guide",
	QUIZ: "Quiz",
	SOULCIRCUS: "Soulcircus",
	UNKNOWN: "--",
} as const;
export type BugProductKeys = keyof typeof BUG_REPORT_PRODUCTS;
export type AvailableBugProduct = (typeof BUG_REPORT_PRODUCTS)[BugProductKeys];

export const BUG_REPORT_CATEGORIES = {
	AUTH: "Authentification",
	DOC: "Documentation",
	ERGONOMICS: "Ergonomics",
	FUNCTIONAL: "Functional",
	GRAPHICAL: "Graphical",
	OTHER: "Other",
	PERFORMANCE: "Performance",
	TRANSLATION: "Translation",
} as const;
export type BugCategoryKeys = keyof typeof BUG_REPORT_CATEGORIES;
export type AvailableBugCategory =
	(typeof BUG_REPORT_CATEGORIES)[BugCategoryKeys];

export const BUG_REPORT_STATUS = {
	OPEN: "open",
	REJECTED: "rejected",
	CLOSED: "closed",
} as const;
export const bugStatusSchema = z.nativeEnum(BUG_REPORT_STATUS);
export type BugStatusKeys = keyof typeof BUG_REPORT_STATUS;
export type AvailableBugStatus = (typeof BUG_REPORT_STATUS)[BugStatusKeys];

export const bugReportSchema = z.object({
	_id: z.string(), // Managed by mongo
	acceptToBeContacted: z.boolean().default(false),
	attachments: z.array(z.string()).optional(),
	categories: z
		.array(z.nativeEnum(BUG_REPORT_CATEGORIES))
		.default([BUG_REPORT_CATEGORIES.OTHER]),
	createdAt: z.date(),
	description: z.string().optional(),
	email: z.string().email().optional(),
	navigator: z.object({
		userAgent: z.string(),
		language: z.string(),
	}),
	product: z.nativeEnum(BUG_REPORT_PRODUCTS),
	reportPageUrl: z.string().url(),
	status: bugStatusSchema.default(BUG_REPORT_STATUS.OPEN),
	updatedAt: z.date(),
});
export type BugReport = z.infer<typeof bugReportSchema>;

export const bugReportCreateInputSchema = bugReportSchema.omit({
	_id: true,
	createdAt: true,
	updatedAt: true,
});
export type BugReportCreateInput = z.infer<typeof bugReportCreateInputSchema>;

export const reportImageSchema = z.object({
	_id: z.string(), // The URL of the image
	nbChecks: z.number(),
});
export type ReportImageDocument = z.infer<typeof reportImageSchema>;
