import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { dequeueNotification } from "reducers/notificationsReducer";
import { AppState } from "../../configureStore";
import SnackbarWrapper from "./SnackbarWrapperComponent";

export type InformationVariant = "success" | "warning" | "error" | "info";

const Notifications = ({
	notification,
	dequeue,
}: ConnectedProps<typeof connector>) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		if (notification) {
			enqueueSnackbar("", {
				persist: notification.persist,
				content: (key) =>
					SnackbarWrapper({
						parsedMessage: notification,
						onClose: () => {
							closeSnackbar(key!);
						},
						key,
					}),
			});

			dequeue();
		}
	}, [closeSnackbar, enqueueSnackbar, notification, dequeue]);

	return <></>;
};

function mapStateToProps(state: AppState) {
	return {
		notification: state.notifications[0] || false,
	};
}

const mapDispatchToProps = {
	dequeue: dequeueNotification,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Notifications);
