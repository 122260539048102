import { L1 } from "@cruncho/cruncho-shared-types";
import invert from "lodash/invert";

export const urlToCategoryMappings: Record<string, L1> = {
	"see-and-do": "visit",
	hotels: "hotels",
	"live-events": "events",
	"eat-and-drink": "restaurants",
};

type LocalMapping = typeof urlToCategoryMappings;

export const aliasToL1 = (alias: string): L1 =>
	urlToCategoryMappings[alias] ?? "visit";

export const l1ToAlias = (category: string): keyof LocalMapping =>
	invert(urlToCategoryMappings)[category];
