import {
	RecoNameQuery,
	SearchReco,
	StructuredReco,
} from "@cruncho/cruncho-shared-types";
import { AppThunk } from "configureStore";
import {
	receiveIdFromHash,
	requestIdFromHash,
} from "reducers/decodedHashReducer";
import {
	receiveRecosByName,
	requestRecosByName,
} from "reducers/recoNameSearchReducer";
import { Dispatch } from "redux";
import { cleanRecoCachedApi, fetchOriginalAndModified } from ".";
import { adminApiPath, apiTsInstance } from "../common";
import { UpdateRow } from "../components/searchComponents/SearchResultTable/SearchResultTable";
import {
	HideBatchResult,
	hideBatchBegin,
	hideBatchCompleted,
} from "../reducers/hideBatchReducer";
import { consoleLogger } from "../utils/consoleLogger";
import * as notification from "./notificationActions";

/**
 * ACTION CREATORS
 */

export function fetchRecosByName({ name, destinationSlug }: RecoNameQuery) {
	return async (dispatch: Dispatch) => {
		dispatch(requestRecosByName({ name, destinationSlug }));

		return apiTsInstance
			.post<SearchReco[]>(`${adminApiPath}/reco-name-search/`, {
				name,
				destinationSlug,
			})
			.then(({ data = [] }) => dispatch(receiveRecosByName({ data })))
			.catch((error) => dispatch(receiveRecosByName({ data: [], error })));
	};
}

export function fetchRecoByHash(hash: string): AppThunk {
	return async (dispatch) => {
		dispatch(requestIdFromHash());
		return apiTsInstance
			.get(`${adminApiPath}/decode/${hash}`)
			.then((res) => {
				const id: number = res.data;
				dispatch(receiveIdFromHash({ data: id }));
				dispatch(fetchOriginalAndModified(id));
			})
			.catch((error) => {
				dispatch(notification.errorFetchReco(error));
				dispatch(receiveIdFromHash({ data: 0, error }));
			});
	};
}

async function setHideReco({
	id,
	hide,
	hideReason,
}: {
	id: number;
	hide: boolean;
	hideReason?: string;
}): Promise<HideBatchResult> {
	try {
		const reco = (
			await apiTsInstance.get<StructuredReco>(
				`${adminApiPath}/managed-recommendation/${id}`,
			)
		).data;

		if (hide) {
			reco.isPinned = false;
			reco.hideReason = consoleLogger(hideReason, "updated hidereason");
		} else {
			// If `!hide`, there should not be any hide reason
			delete reco.hideReason;
		}
		reco.hide = hide;

		await apiTsInstance.put(`${adminApiPath}/managed-recommendation/${id}`, reco);
		await cleanRecoCachedApi(id);
	} catch (err) {
		console.error("Error when trying to hide a reco", err);
		return { id, error: err };
	}
	return { id, error: false };
}

export function setHideBatch({
	hideReason,
	updateRows,
}: UpdateHideBatch): AppThunk {
	return async (dispatch) => {
		dispatch(hideBatchBegin());
		const promises = updateRows.map(({ id, hide }: UpdateRow) =>
			setHideReco({
				id,
				hide,
				hideReason,
			}),
		);
		return Promise.all(promises).then((res) => {
			dispatch(hideBatchCompleted(res));
			dispatch(notification.feedBackHideBatchCompleted());
		});
	};
}

export interface UpdateHideBatch {
	updateRows: UpdateRow[];
	hideReason?: string;
}
