import { ClipboardCopyButton } from "@cruncho/components";
import { BugReportCard } from "@cruncho/components/BugReportCard";
import { defaultTranslationFunction } from "@cruncho/utils/helpers";
import CreateIcon from "@mui/icons-material/Add";
import BuildIcon from "@mui/icons-material/Build";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CategoriesIcon from "@mui/icons-material/ClearAll";
import DescriptionIcon from "@mui/icons-material/Description";
import FeedbackIcon from "@mui/icons-material/Feedback";
import LabelIcon from "@mui/icons-material/Label";
import MenuIcon from "@mui/icons-material/Menu";
import MovieIcon from "@mui/icons-material/Movie";
import RateReviewIcon from "@mui/icons-material/RateReview";
import SearchIcon from "@mui/icons-material/Search";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MuiDivider, { DividerProps } from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MuiListItemButton, {
	ListItemButtonProps,
} from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { apiTsRoot } from "common";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { putChanges } from "../actions";
import { paths } from "../App";
import { AppState } from "../configureStore";
import { createPropsForProperty } from "../defaultDispatchProps";
import { cmsStyleClasses } from "./styles/cmsStyles";
import "@cruncho/components/BugReportCard.css";

const iconClassName = "text-crunchoLogoGray";

const Divider = (props: DividerProps) => (
	<MuiDivider className="bg-crunchoLogoGray mx-4 my-0" {...props} />
);

const ListItemButton = (props: ListItemButtonProps) => (
	<MuiListItemButton
		// Use white instead of black because it wasn't visible otherwise
		className="hover:bg-white/10"
		{...props}
	/>
);

type DrawerProps = {
	className: string;
	isSuperAdmin: boolean;
	navigateTo: (path: string) => void;
	openFeedbackModal: () => void;
};

const Drawer = ({
	className,
	isSuperAdmin,
	navigateTo,
	openFeedbackModal,
}: DrawerProps) => (
	<Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
		<div className={className}>
			<img
				src="../../img/cruncho-logo-ondark.svg"
				alt="Cruncho"
				width="100%"
				height="100%"
			/>
		</div>

		<List>
			<ListItem>
				<ListItemText primary={"Edit this Recommendation"} />
			</ListItem>
			<Divider />
			<ListItemButton
				key={"Basic Info"}
				onClick={() => {
					navigateTo(paths.basic);
				}}
			>
				<ListItemIcon>
					<DescriptionIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Basic Info"} />
			</ListItemButton>
			<ListItemButton
				key={"Photos"}
				onClick={() => {
					navigateTo(paths.photos);
				}}
			>
				<ListItemIcon>
					<CameraAltIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Photos"} />
			</ListItemButton>
			<ListItemButton
				key={"Videos"}
				onClick={() => {
					navigateTo(paths.videos);
				}}
			>
				<ListItemIcon>
					<MovieIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Videos"} />
			</ListItemButton>

			<ListItemButton key={"Reviews"}>
				<ListItemIcon>
					<RateReviewIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText
					primary={"Reviews"}
					onClick={() => {
						navigateTo(paths.reviews);
					}}
				/>
			</ListItemButton>

			<ListItemButton
				key={"category"}
				onClick={() => {
					navigateTo(paths.categories);
				}}
			>
				<ListItemIcon>
					<CategoriesIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Categories"} />
			</ListItemButton>
		</List>

		<List>
			<ListItem>
				<ListItemText primary={"Other Recommendations"} />
			</ListItem>
			<Divider />

			<ListItemButton
				key={"Search"}
				onClick={() => {
					navigateTo(paths.search);
				}}
			>
				<ListItemIcon>
					<SearchIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Search"} />
			</ListItemButton>
			<ListItemButton
				key={"Create"}
				onClick={() => {
					navigateTo(paths.create);
				}}
			>
				<ListItemIcon>
					<CreateIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Create"} />
			</ListItemButton>
			<ListItemButton key={"Labels"} onClick={() => navigateTo(paths.labels)}>
				<ListItemIcon>
					<LabelIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary="Labels" />
			</ListItemButton>
		</List>

		<List hidden={!isSuperAdmin}>
			<ListItem key={"Super Admin"}>
				<ListItemText primary={"Super Admin"} />
			</ListItem>
			<Divider />

			<ListItemButton
				key={"translation"}
				onClick={() => {
					navigateTo(paths.translationFR);
				}}
			>
				<ListItemIcon>
					<CategoriesIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Translations to French"} />
			</ListItemButton>
			<ListItemButton
				key={"MasterMode"}
				onClick={() => {
					navigateTo(paths.mastermode);
				}}
			>
				<ListItemIcon>
					<BuildIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"MasterMode"} />
			</ListItemButton>

			<ListItemButton
				key={"Curation"}
				onClick={() => {
					navigateTo(paths.curation);
				}}
			>
				<ListItemIcon>
					<BuildIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Curation"} />
			</ListItemButton>
		</List>

		<List sx={{ marginTop: "auto", flexGrow: 0 }}>
			<ListItemButton key={"Feedback"} onClick={openFeedbackModal}>
				<ListItemIcon>
					<FeedbackIcon className={iconClassName} />
				</ListItemIcon>
				<ListItemText primary={"Feedback"} />
			</ListItemButton>
		</List>
	</Box>
);

const MainNav = ({
	id,
	img,
	isSuperAdmin,
	name,
	saveButtonVisible,
	saveChanges,
}: ConnectedProps<typeof connector>) => {
	const [mobileOpen, setMobileOpen] = useState(false);
	const [feedbackModal, setFeedbackModal] = useState(false);

	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	useEffect(() => {
		document.addEventListener("keyup", (e) => {
			if ((e.ctrlKey || e.metaKey) && e.key === "Enter") {
				saveChanges();
			}
		});
	}, [saveChanges]);

	return (
		<div>
			<AppBar position="fixed" className={cmsStyleClasses.cmsAppBar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-labelledby="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={cmsStyleClasses.cmsAppBarMenuButton}
						size="large"
					>
						<MenuIcon />
					</IconButton>

					<Avatar alt="" src={img} />

					<Typography variant="h5" className={cmsStyleClasses.cmsAppBarTitle}>
						{`${name || " "}`}
						<Tooltip title="Copy" disableFocusListener={true}>
							<ClipboardCopyButton data={id.toString()}>
								{id ? ` ID: ${id}` : ""}
							</ClipboardCopyButton>
						</Tooltip>
					</Typography>
					{saveButtonVisible && (
						<Button
							variant="contained"
							color="secondary"
							onClick={saveChanges}
							className={cmsStyleClasses.cmsAppBarFancyButton}
							size="medium"
							title="Or Ctrl+Enter"
						>
							Save & Publish
						</Button>
					)}
				</Toolbar>
			</AppBar>

			<nav className={cmsStyleClasses.drawer}>
				<Hidden smUp implementation="css">
					<MuiDrawer
						variant="temporary"
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: cmsStyleClasses.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<Drawer
							className={cmsStyleClasses.toolbar}
							isSuperAdmin={isSuperAdmin}
							navigateTo={navigate}
							openFeedbackModal={() => setFeedbackModal(true)}
						/>
					</MuiDrawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<MuiDrawer
						classes={{
							paper: cmsStyleClasses.drawerPaper,
						}}
						variant="permanent"
						open
					>
						<Drawer
							className={cmsStyleClasses.toolbar}
							isSuperAdmin={isSuperAdmin}
							navigateTo={navigate}
							openFeedbackModal={() => setFeedbackModal(true)}
						/>
					</MuiDrawer>
				</Hidden>
			</nav>

			<Dialog
				open={feedbackModal}
				onClose={() => setFeedbackModal(false)}
				sx={{ borderRadius: "1rem" }}
				PaperProps={{ sx: { padding: "0.5rem" } }}
			>
				<BugReportCard
					apiEndpoint={`${apiTsRoot}bug-report`}
					attachments
					t={defaultTranslationFunction}
					product="CMS"
				/>
			</Dialog>
		</div>
	);
};

function mapStateToProps(state: AppState) {
	return {
		name: createPropsForProperty(state, "name").value || "",
		id: state.selectedReco.id,
		img: get(state, "selectedReco.savedModifiedReco.reco.photos[0].url", ""),
		isSuperAdmin: state.adminGroup.adminGroup.superAdmin,
		saveButtonVisible: state.saveButton.visible,
	};
}

const mapDispatchToProps = {
	saveChanges: putChanges,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(MainNav);
