// const drawerWidth = 240;
// const contentWidth = 700;

export interface classnames {
	[key: string]: any;
	photos: {
		[key: string]: any;
		topTitleBar: any;
	};
}

export const cmsStyleClasses = {
	avatar: "avatar",
	button: "button",
	card: "card",
	categoriesSection: "categoriesSection",
	cmsAppBar: "cmsAppBar",
	cmsAppBarFancyButton: "cmcmsAppBarFancyButton",
	cmsAppBarMenuButton: "cmsAppBarMenuButton",
	cmsAppBarTitle: "cmsAppBarTitle",
	comments: "comments",
	content: "content",
	dense: "dense",
	drawer: "drawer",
	drawerPaper: "drawerPaper",
	fab: "fab",
	fancybutton: "fancybutton",
	infoCard: "infoCard",
	menu: "menu",
	menuButton: "menuButton",
	photosTopTitleBar: "photosTopTitleBar",
	photosBottomTitleBar: "photosBottomTitleBar",
	photosGridList: "photosGridList",
	photosImg: "photosImg",
	root: "root",
	snack_error: "snack_error",
	snack_icon: "snack_icon",
	snack_iconVariant: "snack_iconVariant",
	snack_info: "snack_info",
	snack_message: "snack_message",
	snack_success: "snack_success",
	snack_warning: "snack_warning",
	textField: "textField",
	toolbar: "toolbar",
};
