import { configureStore } from "@reduxjs/toolkit";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import rootReducer from "./reducers/index";

export const store = configureStore({
	reducer: rootReducer,
});

export type AppState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType | Promise<ReturnType>,
	AppState,
	unknown,
	Action
>;

export type AppDispatch = typeof store.dispatch;
