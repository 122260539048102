export const extractCookieKeys = (cookieString: string): string[] => {
	const cookies = cookieString.split("; ");

	const cookieKeys = cookies.map((cookie) => {
		const keyValuePair = cookie.split("=");
		return keyValuePair[0];
	});

	return cookieKeys;
};

export const clearAllCookies = (): void => {
	const cookiesToClear = extractCookieKeys(document.cookie);

	cookiesToClear.forEach((cookieName) => {
		document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
	});
};
